@import '../../../styles/common';

.label-wrapper {
  display: flex;
  justify-content: space-between;
  width: 268px;
  height: 20px;
  padding: 8px 16px;
  border-radius: 4px;

  &.hovered {
    background-color: get-color($primary, 200);
    cursor: pointer;
  }

  span {
    width: 270px;
    margin-left: 1px;
    font-family: $sub18;
    font-size: 16px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .img {
    width: 13px;
    height: 13px;
  }
}
