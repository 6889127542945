@import "src/styles/common";

.status-mark {
  @include flexCenter;
  @include base-text($publicSansSemiBold, 1.2rem, 1.4rem);
  padding: .4rem 1.2rem;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;

  &.approved {
    border-color: #A7E1A8;
    background-color: #F4FBEE;
    color: #176D49;
  }

  &.pending {
    border-color: #D1D2D4;
    background-color: #F9F9F9;
    color: #5F636B;
  }

  &.rejected {
    border-color: #F3ADA2;
    background-color: #FFF8F8;
    color: #B33733;
  }
}
