@import 'src/styles/common';

.download-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 5rem 2rem 2rem;
  height: 7rem;
  position: relative;
  opacity: 1;
  transition: .4s opacity ease-in-out;

  .loading {
    opacity: .1;
    transition: .4s opacity ease-in-out;
  }

  // loader will be upon the button
  .rounded-button {
    &.bright {
      position: relative;
      z-index: 10;
    }
  }
}
