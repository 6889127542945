@import './styles/variables';
@import './styles/_common.scss';
@import './styles/fonts.scss';

* {
  margin:0;
  padding: 0;
}

html {
  // 1rem = 10px => 10 / 16 * 100 = 62,5%
  font-size: 62.5%;
  height: 100%;
}

#root {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: $fontRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  padding: 0;
  font-size: 1.6rem;
  min-height: 100%;
}

input {
  &:disabled {
    background-color: transparent;
  }
}

appcues-checklist {
  z-index: 999998 !important;
}
