@import './../../../../styles/common';

.departments-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.5em;
  padding: 0.25em 5% 0.25em 5%;
  border-radius: 3px;
  border: solid 1px #000584;
  cursor: pointer;

  input {
    display: flex;
    align-items: center;
    width: 100%;

    font-size: 1em;
    font-family: $title40;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: none;
    outline: none;
    color: #000000;
  }

  span {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 0 1px;
    font-size: 1em;
    font-family: $title40;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #000000;
  }
}
