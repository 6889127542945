@import './../../../styles/common';

.info-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.7rem;
  border-radius: 4px;
  min-height: 7rem;

  &--label {
    line-height: 1.4;
    color: get-color($bg, 900);
    font-size: 1.2rem;
    font-family: $body12;
    text-align: left;
    overflow: hidden;

    @include respond (phone){
      min-width: 15rem;
    }

    b {
      font-family: $sub12;
    }
  }

  &.error {
    background-color: get-color($error, 100);

    .info-box--label{
      .error-text {
        font-family: $sub12;
        color: get-color($error, 500);
      }
    }
  }

  &.info {
    background-color: get-color($primary, 100);
  }

  &.success {
    background-color: get-color($secondary, 100);
  }
}

