@import './../../../styles/common';

.search-autocomplete {
  position: relative;
  background-color: $white;
  z-index: 1;

  .search-icon {
    position: absolute;
    width: 1.4rem;
    height: 1.4rem;
    top: 1.2rem;
    left: 1.2rem;

    path {
      fill: get-color($text, 500);
    }
  }

  input {
    border-radius: 8px;
    border: 1px solid get-color($text, 400);
    outline: none;
    padding: 1rem 1.2rem 1rem 3.2rem;
    width: 222px;
    font-size: 12px;
    font-family: $title18;
  }

  .suggestions {
    position: absolute;
    width: 100%;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid get-color($bg, 100);
    border-top-width: 0;
    top: 3.6rem;
    list-style: none;
    margin-top: 0;
    max-height: 9.6rem;
    overflow-y: auto;
    padding: 1.4rem 0;
  }

  .suggestions li {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    font-family: $body14;
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: get-color($text, 800);

    &:first-child {
      padding-top: 0;
    }
  }

  .suggestion-active,
  .suggestions li:hover {
    color: get-color($primary, 800);
    cursor: pointer;
  }
}
