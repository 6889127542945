@import '../../../../styles/common';

.group-boxes__simple-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 50px;
  max-width: 150px;
  margin: 8px 8px 8px 0;
  padding: 8px 14px;
  border-radius: 4px;
  border: solid 1px get-color($bg, 500);

  &.secondary {
    border-color: #f8f8f8;
    background: #f8f8f8;
    padding: 15px;
    max-width: 250px;

    &.not-valid {
      border-color: get-color($error, 500);
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    span {
      font-family: $title18;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &.loading {
    opacity: 1 !important;
  }

  span {
    margin-right: 8px;
    font-family: $fontBold;
    font-size: 12px;
    line-height: 1.75;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 10px;
    height: 10px;
    margin-left: 8px;
    cursor: pointer;
  }

  .loader-container_simple-box {
    width: 1.8rem;
    height: 1.6rem;

    .loader-small {
      position: initial;
    }
  }
}
