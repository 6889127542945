@import './../../../styles/common';

.limit-edit {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 12.5rem;
  padding: 1.2rem 0 .4rem;
  margin: 0 1.2rem;
  cursor: pointer;
  border: 1px solid $white;

  .edit-pen {
    width: 1.5rem;
    height: 1.4rem;
    min-width: 1.5rem;
  }

  &.active {
    border-radius: 3px;
    border: 1px solid get-color($primary, 800);
  }
}
