@import 'src/styles/common';

.item-select {
  background-color: $white;
  box-sizing: border-box;
  @include flexCenter;
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
  padding: 2.1rem 3.1rem;
  border: 1px solid get-color($bg, 300);

  &.selected {
    padding: 2rem 3rem;
    border: 2px solid $black;
  }

  &__icon {
    margin-right: .8rem;
  }

  &__label {
    @include base-text($body12, 1.4rem, 1.6rem, get-color($text, 900));
  }
}
