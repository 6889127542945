@import "src/styles/common";

.upload-section {
  background-color: $white;
  border: 1px dashed get-color($bg, 300);
  border-radius: 15px;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  opacity: 1 !important;
  padding: 5.8rem  1rem 0 1rem;

  .operation {
    &-message {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      color: $black;
      font-size: 1.6rem;
      line-height: 2rem;
      font-family: $title18;

      .cancel-icon {
        cursor: pointer;
        margin-left: .4rem;
      }
    }
  }

  &.with-data {
    border: 2px solid get-color($bg, 200);
  }

  &.highlighted {
    background: #F5F7FA;
    border: 2px dashed get-color($primary, 700)!important;

    .upload-section {
      &__icon,
      &__button,
      &__description {
        pointer-events: none;
      }
    }
  }

  &.error {
    background: get-color($error, 100);
    border: 2px dashed get-color($error, 700);

    .error-message {
      color: get-color($error, 700);
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-family: $body12;
      margin-top: .8rem;
    }
  }

  &.with-error-message {
    padding-top: 3.2rem;
  }

  &.uploading {
    border: 2px solid get-color($primary, 500);

    .loader {
      width: 100%;
      height: 4.8rem;
      position: relative;

      .lds-eclipse {
        div {
          width: 13.7rem;
          height: 13.7rem;
          transform-origin: 6.9rem;
          box-shadow: 0 4px 0 0 get-color($primary, 500);
          left: 3.3rem;
          top: -.6rem;
        }
      }
    }

    .uploading-message {
      margin-top: 1.2rem;
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-family: $sub18;
      color: get-color($text, 700);
    }
  }

  &__icon {
    width: 4.8rem;
    height: 4.8rem;
    min-width: 4.8rem;
    min-height: 4.8rem;
  }

  &__button {
    margin-top: 1.2rem;
    height: 3.2rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: .8rem 1.6rem;

    &-reupload {
      background: get-color($error, 500);
      margin-top: .8rem;
    }
  }

  &__description {
    margin-top: .8rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: get-color($text, 800);
    font-family: $body12;
  }
}
