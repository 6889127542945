@import 'src/styles/common';

.progress-info-marker {
  display: flex;
  position: relative;
  width: 100%;

  &.default {
    opacity: 0;
  }

  img {
    position: relative;
    overscroll-behavior: contain;
    z-index: 6;
    width: 1.8rem;
    height: 1.8rem;
    margin-top: .7rem;
    cursor: pointer;
  }
}
