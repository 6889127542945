@import 'src/styles/common';

.documents-uploader {
  $this: &;

  width: 100%;
  box-sizing: border-box;
  padding: 3rem 2rem;
  background: $white;
  border: 1px dashed map-get($bg, 300);
  border-radius: 15px;

  &.drag-over {
    border-color: map-get($primary, 700);
    background: #F5F7FA;;

    * {
      pointer-events: none;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    margin-right: .7rem;
  }

  &__files {
    & > div::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      margin: 1.5rem 0;
      background: map-get($bg, 300);
    }
  }

  &__button {
    margin: .5rem 0 1rem 0;
    padding: .8rem 1.6rem;
    background: map-get($bg, 900);
    border: none;
    border-radius: 100px;
    cursor: pointer;
    @include base-text($publicSansSemiBold, 1.4rem, 1.6rem, $white);

    span {
      display: flex;
      align-items: center;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__hint {
    @include base-text($publicSansSemiBold, 1.2rem, 2.2rem, map-get($text, 700));
  }

  &__accepted-files {
    margin: 1.2rem 0 0;
    @include base-text($publicSansNormal, 1.2rem, 2.2rem, map-get($text, 500));
  }
}
