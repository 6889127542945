@import '../../../../styles/common';

.group-boxes__add-button {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 8px 0;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &.disabled.bright {
    opacity: .5;
  }

  &.secondary {
    border-radius: 4px;
    padding: 15px;
    background: #f8f8f8;
    height: auto;
    border: 1px solid #f8f8f8;

    span {
      color: get-color($text, 600);
      font-size: 14px;
      line-height: 20px;
      text-transform: unset;
    }
  }

  &.bright  {
    background: $white;
    border: 1px solid get-color($bg, 300);
    border-radius: 10rem;
    outline: none;
    box-sizing: border-box;
    padding: 0.5rem 1.9rem;
    margin: 0;

    span {
      margin: 0;
      @include base-text($publicSansSemiBold, 1.2rem, 2rem, get-color($text, 900));
    }
  }

  span {
    margin: 0 8px;
    font-family: $button16;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000584;
  }

  img {
    width: 23px;
    height: 23px;
  }

  .loader-container_add-button {
    width: 1.8rem;
    height: 1.6rem;

    .loader-small {
      position: initial;
    }
  }
}
