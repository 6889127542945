@import '../../../../styles/common';

.group-boxes__simple-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 50px;
  max-width: 150px;
  margin: 8px 8px 8px 0;
  padding: 8px 14px 8px 0;
  border-radius: 4px;
  border: solid 1px get-color($bg, 500);

  &.secondary {
    height: 34px;

    input {
      font-size: 14px;
      line-height: 20px;
    }
  }

  input {
    font-family: $fontBold;
    font-size: 12px;
    line-height: 1.75;
    width: 100%;
    border: none;
    text-indent: 14px;
    outline: none;
  }
}
