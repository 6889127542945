@import './../../../styles/common';

.divider {
  width: 100%;

  &__vertical {
    width: 2px;
    border-right: 1px solid get-color($bg, 300);
    margin: 0 $divider-default-vertical-margin;
  }

  &__horizontal {
    height: 2px;
    border-top: 1px solid get-color($bg, 300);
    margin: $divider-default-horizontal-margin 0;
  }

  &--main {
    border-color: $divider-border-color-main;
    margin: $divider-main-default-horizontal-margin 0;
  }
}
