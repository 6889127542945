@import '../../../styles/common';

.tag-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .input-with-select:not(:last-child) {
    margin-bottom: .8rem;
  }

  &.disabled {
    pointer-events: none;

    .group-boxes__simple-box, .group-boxes__add-button {
      opacity: .6;
    }
  }
}
