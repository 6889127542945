@import '../../../styles/common';

.auth-checkbox {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;


  @include respond(phone){
    margin-top: 1rem;
  }

  &.disabled {
    justify-content: center;

    img {
      display: none;
    }
  }

  img {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 1.1rem;
    cursor: pointer;
  }

  span {
    font-size: 1.2rem;
    font-family: $body12;
    color: get-color($text, 800);
  }
}
