@import './../../../styles/common';

.progress-ring {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clip-path: circle(75px);
  cursor: pointer;

  &.default {
    width: 100%;
    height: 100%;
  }

  .ring-svg {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: center;

    &.base {
      @include balanceCircle(0, get-color($error, 500));
    }

    &.bottom {
      @include balanceCircle(1, get-color($warning, 500));
    }

    &.intermediate {
      @include balanceCircle(2, #4cca8d);
    }

    &.top {
      @include balanceCircle(3, get-color($primary, 800));
    }

    .balance-circle {
      stroke: get-color($text, 200);
      fill: transparent;
    }
  }

  .ring-inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 3;
  }

  .ring-title {
    font-family: $body12;
    color: get-color($text, 900);
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
  }

  .ring--sub-description__row {
    flex-direction: row;
    z-index: 3;

    .ring-info-marker {
      width: 16px;
      height: 16px;
      margin: 0 0 -3px 3px;
      cursor: pointer;
    }
  }

  .ring-description {
    font-family: $body12;
    color: get-color($text, 600);
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
  }
}

.spent-resources__tooltip {
  position: absolute;
  min-width: 17rem;
  transform: translate(-46%, -110%);
  top: 0;
  left: 0;
  z-index: 4;

  &--content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: .8rem 2rem 1.2rem 2rem;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 2px 4px 3px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;

    .resources-description__row {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      div {
        min-width: .6rem;
        min-height: .6rem;
        border-radius: 500px;

        &.circle-green {
          background-color: #4cca8d;
          border: 1px solid #4cca8d;
        }

        &.circle-blue {
          background-color: get-color($primary, 800);
          border: 1px solid get-color($primary, 800);
        }

        &.circle-orange {
          background-color: get-color($warning, 500);
          border: 1px solid get-color($warning, 500);
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 1rem;
        width: 100%;
        height: 100%;

        &__label {
          font-family: $title18;
          font-size: 1.4rem;
          line-height: 2rem;
          color: get-color($text, 800);
          margin-bottom: .2rem;
          white-space: nowrap;
        }

        &__value {
          color: get-color($text, 600);
          font-size: 1.2rem;
          line-height: 1.6rem;
          font-family: $sub12;
          white-space: nowrap;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // &--content:after {
  //   @include tooltipContentAfter;
  // }
}
