@import 'src/styles/common';

.dropdown-search__input-chevron {
  $chevronSize: 1.8rem;

  width: $chevronSize;
  min-width: $chevronSize;
  height: $chevronSize;
  min-height: $chevronSize;
  transform: rotate(0deg);
  transition: .2s all ease-in-out;
  transform-origin: 50%;
  margin-right: .4rem;

  &.expanded {
    transition: .2s all ease-in-out ;
    transform: rotate(-180deg);
  }
}
