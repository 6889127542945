@import './../../../styles/common';

.small-card {
  position: relative;
  width: 9.4rem;
  min-width: 9.4rem;
  height: 6rem;
  background: $black;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  .card-type {
    position: absolute;
    margin-left: .9rem;
    bottom: 1.6rem;
    font-family: $title18;
    font-size: .5rem;
    line-height: .7rem;
    color: $white;
  }

  .card-logo {
    position: absolute;
    width: 2.8rem;
    height: 1rem;
    margin-top: .8rem;
    margin-left: .8rem;
    .cls-6 {
      fill: $white;
    }
  }

  .card-name {
    position: absolute;
    margin-left: .9rem;
    bottom: .8rem;
    font-family: $title18;
    font-size: .7rem;
    line-height: .7rem;
    color: $white;
  }

  .card-brand {
    position: absolute;
    bottom: .6rem;
    right: 1.2rem;
    width: 2.3rem;
    height: 1.4rem;
  }

  &.virtual {
    background: $white;

    .card-type {
      color: get-color($text, 700);
    }

    .card-logo {
      .cls-6 {
        fill: get-color($text, 900);
      }
    }

    .card-name {
      color: get-color($text, 700);
    }

    .card-brand {
      g {
        path {
          fill: get-color($text, 900);
        }
      }
    }
  }
}
