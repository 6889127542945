@import 'src/styles/common';

.sort-by {
  @include pageSortBy;

  .sort-suggestions {
    position: absolute;
    min-width: 10rem;
    top: 2.4rem;
    padding: 1.6rem;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid  get-color($bg, 200);
    display: flex;
    flex-direction: column;
    z-index: 4;

    span {
      font-family: $body14;
      font-size: 1.2rem;
      line-height: 2.4rem;
      color: get-color($text, 800);

      &.active {
        color: get-color($primary, 800);
      }
    }
  }
}