@import '../styles/common';

.app-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 6vh 91vh 3vh;
  grid-column-gap: 9.6rem;
  background-color: $white;

  @include respond(tab-land) {
    grid-template-rows: 10vh 87vh 3vh;
    grid-column-gap: 0;
  }

  @media only screen and (max-width: 81.88rem) {
    grid-column-gap: 1.6rem;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    min-width: 23.3rem;
    flex: 1 1 auto;
    margin-left: auto;
    min-height: 1px;
    grid-column: 1/4;
    grid-row: 2/3;

    &--header {
      display: flex;
      margin-bottom: 6.5rem;

      .header {
        &__logo {
          @include headerLogo;
          margin-right: 3.8rem;
          margin-left: 1.1rem;
          margin-top: .5rem;
        }

        &__profile {
          display: flex;
          cursor: pointer;

          .avatar {
            background-color: get-color($bg, 900) !important;

            span {
              color: get-color($text, 100) !important;
              font-family: $button16 !important;
            }
          }

          &--arrow {
            margin-left: .9rem;

            &.active {
              transform: rotate(180deg);
            }
          }

          &--menu {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 18.2rem;
            margin-top: 5.5rem;
            margin-left: 15.2rem;
            padding: 2rem 1.5rem 1.4rem 1.9rem;
            border-radius: .8rem;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            background-color: $white;
            z-index: 2;

            .profile-menu {
              &__item {
                display: flex;
                margin-bottom: .6rem;
                font-family: $body12;
                font-size: 1.2rem;
                line-height: 1.8rem;
                color: get-color($text, 900);
                cursor: pointer;

                a {
                  text-decoration: none;
                  color: get-color($text, 900);
                }

                img {
                  width: 1.6rem;
                  height: 1.7rem;
                  margin-right: 1rem;
                }

                &--header {
                  flex-direction: column;
                  margin-bottom: 1.6rem;

                  span {
                    font-family: $body12;
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    color: get-color($text, 900);

                    &:first-child {
                      font-family: $sub18;
                      font-size: 1.8rem;
                      line-height: 2.4rem;
                    }
                  }
                }

                &--footer {
                  margin-bottom: 0;
                  justify-content: flex-end;

                  span {
                    font-family: $sub12;
                    line-height: 1.4rem;
                    color: get-color($text, 700);
                  }
                }
              }
            }
          }
        }
      }
    }

    &--content {
      flex: 1 1 auto;
      min-height: .1rem;
      overflow: hidden;
      position: relative;

      &__scrollable {
        min-height: .1rem;
        overflow-y: auto;
        bottom: 0;
        left: 0;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &--menu {
      display: flex;
      flex-direction: column;
      padding-right: 1.4rem;
      flex-shrink: 0;

      .navigation {
        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1.2rem;
          padding: .2rem 0 .2rem 1.4rem;
          font-size: 1.4rem;
          font-family: $title14;
          line-height: 2.5;
          letter-spacing: normal;
          color: get-color($text, 700);
          text-decoration: none;
          cursor: pointer;
          z-index: 1;

          &--title {
            display: flex;
            align-items: center;

            svg {
              width: 2rem;

              .wallet-icon {
                rect,
                path {
                  stroke: get-color($text, 700);
                }
              }
            }

            span {
              padding-left: 1rem;
            }

            &:hover {
              color: get-color($text, 600);

              svg {
                path[stroke] {
                  stroke: get-color($text, 600);
                }

                path[fill] {
                  fill: get-color($text, 600);
                }

                .wallet-icon {
                  rect,
                  path {
                    stroke: get-color($text, 600);
                  }
                }
              }
            }

            &:active {
              color: get-color($text, 800);

              svg {
                path[stroke] {
                  stroke: get-color($text, 800);
                }

                path[fill] {
                  fill: get-color($text, 800);
                }

                .wallet-icon {
                  rect,
                  path {
                    stroke: get-color($text, 800);
                  }
                }
              }
            }
          }

          &.active {
            color: get-color($text, 900);

            svg {
              path[stroke] {
                stroke: get-color($text, 900);
              }

              path[fill] {
                fill: get-color($text, 900);
              }

              .wallet-icon {
                rect,
                path {
                  stroke: get-color($text, 900);
                }
              }
            }

            &:hover {
              span {
                color: initial;
              }
            }
          }

          &--logout {
            justify-content: flex-end;
          }

          .coming-soon {
            border: 1px solid get-color($text, 200);
            @include statusLabelForLink;

            span {
              color: get-color($text, 300);
            }
          }

          .beta-testing {
            border: 1px solid get-color($primary, 200);
            @include statusLabelForLink;

            span {
              color: get-color($primary, 400) !important;
            }
          }
        }
      }
    }

    &--footer {
      display: flex;
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 2rem;

      a {
        margin-right: 2rem;
        font-family: $caption10;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: get-color($text, 800);
        text-decoration: none;

        &:last-child {
          margin-right: 0;
        }
      }

      div {
        .lang-dropdown__header {
          display: flex;
          align-items: center;
          cursor: pointer;
          user-select: none;

          .flag-img {
            width: 1.7rem;
            height: 1.5rem;
            margin-right: .8rem;
          }

          .lang-code {
            color: get-color($text, 800);
            font-family: $title18;
            font-size: 1.2rem;
            line-height: 1.6rem;
            text-transform: capitalize;
          }

          .arrow-img {
            width: .8rem;
            height: .4rem;
            padding-right: .4rem;
            transform: rotate(180deg);

            &.toggled {
              padding-left: .4rem;
              transform: none;
            }

            path {
              stroke: get-color($text, 800);
            }
          }

          &.disabled {
            opacity: 0.4;
            cursor: default;
          }
        }

        .lang-dropdown {
          position: absolute;
          display: flex;
          flex-direction: column;
          width: 12rem;
          max-height: 15rem;
          overflow-y: scroll;
          padding: .8rem .6rem;
          bottom: 2.5rem;
          right: .5rem;
          background-color: $white;
          border: 1px solid get-color($bg, 200);
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          z-index: 5;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none;

          .lang-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 3.2rem;
            margin: .2rem 0;
            border-radius: 5px;
            cursor: pointer;

            .flag-img {
              width: 2rem;
              height: 1.5rem;
              margin-left: 1.6rem;
              margin-right: 1rem;
            }

            &__label {
              color: get-color($text, 900);
              font-family: $title18;
              font-size: 1.2rem;
              line-height: 1.6rem;
            }

            &.selected {
              background-color: get-color($primary, 800);

              .lang-item__label {
                color: $white;
              }
            }
          }
        }

        .lang-dropdown::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }
      }
    }
  }

  &__content {
    grid-row: 2/3;
    grid-column: 4/13;
    height: calc(100vh - 9rem);
    @include respond(tab-land){
      grid-column: 1/13;
    }

    .page-wrapper {
      .content-wrapper::after {
        content: '';
        display: block;
        height: 5.5rem;
        width: 100%;
      }
    }

    .header-wrapper {
      justify-content:flex-start;
      @include respond(tab-land) {
        @include commonContentMargin;
      }

      &.dashboard-header-wrapper-js {
        @include respond(tab-port) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .red-dot {
    path[fill] {
      fill: get-color($tertiary, 500) !important;
    }
  }

  .base-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__tm {
    flex-direction: column;

    .mobile-header {
      display: flex;
      grid-row: 1/2;
      grid-column: 1/13;
      justify-content: space-between;
      height: 100%;
      @include commonContentMargin;

      &__link{
        display: flex;
        align-items: center;
      }

      .header__logo {
        @include headerLogo;
      }
    }
  }
}

.app-overlay {
  position: fixed;
  z-index: 5;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: get-color($bg, 900);

  &.agreement {
    z-index: 6;
  }
}

.bm-item {
  &:focus {
    outline: none;
  }
}

.bm-burger-button {
  position: fixed;
  width: 2.4rem;
  height: 2rem;
  top: calc( 3vh - 1.2rem); // 3vh - 1/2 of height grid-col-1

  @include respond(tab-land) {
    top: calc(5vh - 1.2rem);
  }
}

.bm-burger-bars {
  background: $black;
  border-radius: 4px;
}

.bm-cross-button {
  display: none;
}

.bm-menu-wrap {
  left: 0;
}

.bm-menu {
  background: $white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .app-container__sidebar--footer {
    width: 20.5rem;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    margin-bottom: 4rem;

    div {
      .lang-dropdown {
        z-index: 5;
        bottom: 6.5rem;
        right: 0;
        left: 11.8rem;
      }
    }
  }
}

.mobile-menu-header {
  display: flex !important;
  align-items: center;
  margin-left: 1.4rem;
  margin-bottom: 2rem;

  .header__logo {
    margin-top: 0 !important;
  }

  .avatar {
    background-color: get-color($bg, 900) !important;

    span {
      color: $white !important;
    }
  }
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
