@import '../../../styles/common';

.two-factor-input {
  height: 5.5rem;
  width: 5rem;
  border-radius: 4px;
  font-family: $fontBold;
  font-size: 1.8rem;
  line-height: 2.4;
  text-align:center;
  border: 1px solid get-color($bg, 500);
  outline: none;

  &:focus {
    border: 1px solid get-color($primary, 800);
  }

  @include respond(phone){
    height: 4rem;
    width: 3.6rem;
    font-size: 1.4rem;
  }

  &--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--separator {
    flex-basis: 1.2rem;
    height: 1px;
    background-color: get-color($bg, 500);
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
