@import '../../../styles/common';

.info-message {
  display: flex;
  width: 1058px;
  height: 0;
  overflow-y: hidden;
  border-radius: 4px;
  transition-property: height;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

  &.expanded {
    height: 47px;
  }

  &-icon {
    &--container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 47px;
      border-radius: 4px 0 0 4px;
    }
  }

  &-text {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--title {
      font-size: 12px;
      font-family: $fontBold;
      white-space: nowrap;
    }

    &--container {
      display: flex;
      width: 857px;
      align-items: center;
      margin-left: 22px;
    }

    &--separator {
      margin: 0 4px 0 4px;
    }
  }

  &-actions-container {
    width: 110px;
    display: flex;
    justify-content: flex-end;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    color: $mainColor;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &.read {
      text-transform: none;
    }
  }

  @media only screen and (max-width: 1460px) {
    width: 920px;

    &-text {
      &--container {
        width: 720px;
      }
    }
  }

  @media only screen and (max-width: 1360px) {
    width: 860px;

    &-text {
      &--container {
        width: 660px;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    width: 800px;

    &-text {
      &--container {
        width: 600px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
  }
}
