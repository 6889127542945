@import 'src/styles/common';

.currency-dropdown__secondary {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.6rem;
  background: get-color($bg, 100);
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;

  .dropdown-flag__img {
    @include inputFlagStyles;
  }

  span {
    width: 100%;
    @include inputCurrencyCodeStyles;
  }

  .dropdown__container {
    position: absolute;
    width: calc(100% - 20px);
    padding: 1rem;
    top: 4.2rem;
    left: 0;
    max-height: 25rem;
    overflow-y: auto;
    border-radius: 5px;
    background: $white;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);

    .currency-dropdown__secondary {
      padding: .8rem 0 .8rem 1.5rem;
      border-radius: 0;
      background: $white;

      &:hover {
        background-color: get-color($primary, 200);
      }
    }
  }
}
