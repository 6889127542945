@import "../../../styles/common";

.currency-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3.5rem;
  margin-bottom: 3rem;

  .flag-img {
    width: 3.7rem;
    height: 2.8rem;
    border-radius: 3px;
  }

  &--main {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: $title24;
    font-size: 2.4rem;
    line-height: 3rem;
    color: $black;
  }
  &--sub {
    margin-bottom: 1.5rem;
    font-family: $sub12;
    font-size: 1.4rem;
    line-height: 2rem;
    color: get-color($text, 600);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
