@import 'src/styles/common';

.page-sort-section {
  display: none;

  @include respond(phone){
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;

    .filter-item {
      cursor: pointer;

      &__label {
        font-size: 2rem;
        line-height: 2.3rem;
        color: get-color($text, 900);
        font-family: $title24;
      }

      &__icon {
        @include imageActive;
      }
    }
  }
}