@import "src/styles/common";

.alert {
  display: flex;
  align-items: center;
  padding: 1.8rem 1.8rem 1.6rem 1.8rem;
  border-radius: 8px;
  box-sizing: border-box;



  &__description {
    @include base-text($publicSansNormal, 1.2rem, 1.4rem, get-color($text, 900));
  }

  &__status-icon {
    margin-right: .8rem;
  }

  &__close-icon {
    @include iconSize(1.6rem);
    margin-left: auto;
    cursor: pointer;
  }

  &.success {
    background: get-color($success, 100);
    .alert__description{
      color: get-color($success, 800);
    }

    .alert__close-icon {
      path {
        stroke: get-color($success, 800);
      }
    }
  }

  &.info {
    background: get-color($info, 100);
    .alert__description{
      color: get-color($info, 700);
    }
  }
}
