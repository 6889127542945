@import 'src/styles/common';

.data-group {
  display: flex;
  flex-direction: column;
  max-width: 74rem;

  &.without-options {
    flex-direction: row;

    .data-group-description {
      display: flex;
      flex-direction: column;
    }

    .data-group-content {
      &__label {
        display: none;
      }
    }
  }

  @mixin notLastChild {
    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }

  &__title {
    font-family: $title30;
    font-size: 2rem;
    line-height: 2.4rem;
    color: $black;
    margin-bottom: 1.2rem;
  }

  &-content {
    display: flex;
    flex-direction: row;

    @mixin fontStyle($color) {
      font-size: 1.8rem;
      line-height: 3rem;
      font-family: $title18;
      color: $color;
    }

    @include notLastChild;

    &__label {
      display: flex;
      flex-direction: column;
      @include fontStyle(get-color($text, 800));
      width: 30rem;
      min-width: 30rem;
      max-width: 30rem;
      margin-right: 1rem;

      &-optional {
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-family: $body14;
        color: get-color($bg, 300);
      }
    }

    &-data {
      display: flex;
      flex-direction: column;
      width: 100%;
      word-break: break-all;

      &__description {
        @include fontStyle(get-color($text, 900));
        @include notLastChild;
      }
    }
  }
}
