@import 'src/styles/common';

.dropdown-search {
  &__content {
    &-loader {
      position: relative;
      height: 4rem;
    }

    &.no-margin-top {
      padding-bottom: 0 !important;
    }
  }
}
