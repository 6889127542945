@import './../../../styles/common';

.billing-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;

  &__column {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
    .view-transactions {
      font-family: $fontRegular;
      font-size: 10px;
      line-height: 3.5;
      text-transform: uppercase;
      color: #0078fc;
    }
    .billing-info {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-left: 25px;
      cursor: pointer;

      &__label {
        font-family: $caption10;
        font-size: 10px;
        line-height: 1.5;
        color: #9e9e9e;
      }

      &__date {
        position: relative;
        display: flex;
        align-items: center;
        font-family: $title40;
        font-size: 22px;
        line-height: 26px;
        color: get-color($text, 900);

        span {
          white-space: nowrap;
          @include sentence-case();
        }

        img {
          @include imageActive;
        }
      }

      .dropdown {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 16rem;
        min-height: 5rem;
        max-height: 30rem;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 4.4rem 0 0 0;
        padding: 1rem 0 1rem 1.5rem;
        border-radius: 4px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid get-color($bg, 100);
        background-color: $white;
        cursor: pointer;
        z-index: 4;

        &.common-scroll {
          padding: 1rem 1.6rem;
        }

        .simplebar-content {
          display: flex;
          flex-direction: column;
        }

        .item {
          margin-top: .4rem;
          font-family: $body12;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: get-color($text, 900);
          @include sentence-case();

          &.selected {
            color: get-color($primary, 800);
          }

          &.disabled {
            color: get-color($text, 400);
          }

          &:hover {
            color: get-color($primary, 700);
          }
        }
      }
    }
  }
}
