@import '../../../styles/common';

@mixin infoFontStyles {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: $title18;
  color: get-color($text, 800);
}

.connect-sat {
  @include flexColumnCenter;

  input {
    @include noInputAutocompleteBackground(get-color($bg, 100))
  }

  .divider__horizontal {
    width: 80%;
    max-width: 59rem;

    @include respond(phone) {
      width: 100%;
    }
  }

  .default-input__wrapper {
    box-sizing: border-box;
    width: 27.8rem;
    height: 4.4rem;
  }

  &__title {
    //font-size: 2rem;
    //line-height: 2.3rem;
    font-size: 3rem;
    line-height: 4rem;
    font-family: $title14;
    color: $black;
  }

  .last-divider {
    margin-top: 1.8rem;
    margin-bottom: 1.5rem;
  }

  &__description {
    margin-top: 2.4rem;
    color: get-color($text, 800);
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-family: $sub18;
    max-width: 42rem;
    text-align: center;
  }

  .logos-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.4rem;

    &__repeat-icon {
      margin: 0 3.2rem;
    }
  }

  &-form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: .4rem;
    width: 100%;
    opacity: 1;
    transition: .3s opacity ease-in-out;

    &.processing {
      opacity: .7;
      transition: .3s opacity ease-in-out;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      max-width: 27.8rem;
      width: 100%;
      margin: 0 1rem;

      @media (max-width: 42.31em) {
        margin-top: 1rem;
      }

      &__label {
        @include infoFontStyles;
        margin-bottom: .8rem;
      }

      &__error {
        color: get-color($error, 500);
      }

      &__restore-password {
        color: get-color($primary, 800);
        cursor: pointer;
      }

      &__error,
      &__restore-password {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-family: $caption10;
        margin-top: .4rem;
      }
    }
  }

  .first-divider {
    margin-top: 3.8rem;

    @include respond(tab-port) {
      margin-bottom: 1rem;
    }
  }

  &-action-container {
    min-height: 7.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.3rem;

    .loader-box {
      @include flexColumnCenter;

      &__message {
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-family: $title18;
        color: get-color($text, 700);
        margin-top: .8rem;
      }

      .lds-eclipse {
        width: 4.8rem !important;
        height: 4.8rem !important;

        div {
          width: 13.7rem;
          height: 13.7rem;
          transform-origin: 6.85rem 6.86rem;
        }
      }
    }
  }

  &__button {
    padding: 1.4rem 2.4rem;
    font-size: 1.6rem;
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;

    &__text {
      font-family: $body12;
      color: get-color($text, 700);
      font-size: 1.2rem;
      line-height: 1.8rem;

      &:last-child {
        margin-top: 3rem;
      }
    }
  }
}

.modal-sat-connection-success {
  @include flexColumnCenter;

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
  }

  &-description {
    margin-top: 1.2rem;
    width: 30rem;
    text-align: center;

    &__text {
      margin-top: 1.2rem;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: $black;
      font-family: $table-amount;
    }
  }

  &-info {
    margin-top: 1.6rem;
    width: 35rem;
    text-align: center;

    &__text {
      @include infoFontStyles;
    }
  }

  &__button {
    margin: 1.6rem 0 1.7rem 0;
  }
}

.sat-connection-success {
  @include flexColumnCenter;
  margin-top: 3rem;

  &-description {
    @include flexColumnCenter;
    margin-top: 3.2rem;

    &__text {
      font-size: 2rem;
      line-height: 2.3rem;
      font-family: $title24;
      color: $black;
    }
  }

  &-info {
    margin-top: 2.5rem;
    @include flexColumnCenter;

    &__text {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-family: $body14;
      color: get-color($text, 500);
    }
  }

  &__button {
    margin-top: 2.5rem;
  }
}

.sat-connection-failure {
  @include flexColumnCenter;

  &__message {
    margin: .8rem 0 1.1rem 0;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: get-color($warning, 600);
    font-family: $title40;
  }
}

