@import 'src/styles/common';

.pagination {
  display: flex;
  margin-top: 1.8rem;

  &__list {
    display: flex;

    .pagination-item:not(:last-child) {
      margin-right: .7rem;
    }
  }

  &-nav {
    &__previous {
      margin-right: 2rem;
      padding: .6rem 1.2rem .6rem .6rem;

      &-label {
        margin-left: .4rem;
      }
    }

    &__next {
      margin-left: 2rem;
      padding: .6rem .6rem .6rem 1.2rem;

      &-label {
        margin-right: .4rem;
      }
    }

    &__previous,
    &__next {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      border-radius: .6rem;

      &:not(.disabled):hover {
        background: get-color($bg, 200);
      }

      &.disabled {
        cursor: default;

        .pagination-nav {
          &__previous-label,
          &__next-label {
            color: get-color($text, 400);
          }
        }
      }

      &-label {
        @include base-text($publicSansSemiBold, 1.4rem, 2rem, get-color($text, 800));
      }
    }
  }
}
