@import '../../../styles/common';

$currency-mobile-max-width: 380px;

.currency-dropdown {
  position: relative;

  .flag {
    width: 37px;
    height: 28px;
    margin-right: 15px;
    border-radius: 3px;
    overflow: hidden;
  }

  .code {
    margin-right: 5px;
    font-family: $title18;
    font-size: 18px;
    line-height: 24px;
    color: get-color($text, 900);
  }

  .description {
    font-family: $body12;
    font-size: 16px;
    line-height: 24px;
    color: get-color($text, 700);

    @media only screen and (max-width: $currency-mobile-max-width) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 283px;
    height: 28px;
    padding: 10px 15px 10px 20px;
    border-radius: 3px;
    border: solid 1px get-color($bg, 500);
    cursor: pointer;

    &.static {
      cursor: default;
    }

    @media only screen and (max-width: $currency-mobile-max-width) {
      width: 223px;
    }

    .currency__text--wrapper {
      display: flex;
      align-items: center;

      @media only screen and (max-width: $currency-mobile-max-width) {
        width: 190px;
      }
    }

    .arrow {
      width: 20px;
      height: 10px;
    }
  }

  .menu {
    position: absolute;
    width: 298px;
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    margin-top: 5px;
    background: $white;
    border: 1px solid get-color($bg, 200);
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1;

    @media only screen and (max-width: $currency-mobile-max-width) {
      width: 238px;
    }

    .element {
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: get-color($primary, 100);
      }

      &.selected {
        background: get-color($primary, 900);
        &:hover {
          background: get-color($primary, 900);
        }

        .description,
        .code {
          color: get-color($primary, 100);
        }
      }
    }
  }
}
