@import 'src/styles/common';

.beta {
  @include flexCenter;
  padding: .4rem .8rem;
  border: 1px solid get-color($primary, 200);
  box-sizing: border-box;
  border-radius: 5px;

  &__label {
    @include base-text($publicSansSemiBold, 1rem, 1.5rem, get-color($primary, 400));
  }
}
