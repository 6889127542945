@import '../../../styles/common';

.error-box {
  position: absolute;
  display: flex;
  align-items: center;
  width: 26.1rem;
  height: 5rem;
  background-color: get-color($error, 200);
  border-radius: 3px;
  cursor: default;

  img {
    width: 1.9rem;
    height: 1.6rem;
    margin: 0 1.5rem;
  }

  span {
    overflow: hidden;
    position: relative;
    line-height: 1.5em;
    max-height: 3em;
    margin-right: 1.5em;
    padding-right: 1em;
    font-size: 1rem;
    font-family: $body12;
  }

  span {
    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background-color: get-color($error, 200);
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 7%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: get-color($error, 200);
    border-bottom: 0;
    margin-left: -.6rem;
    margin-bottom: -.6rem;
  }

  &.right-box {
    &:after {
      content: none;
    }
  }

  &.bottom-box {
    &:after {
      top: -.6rem;
      border-top: 0;
      border-bottom: .6rem solid #ffe9e9;
    }
  }
}
