@import '../../../../styles/common';

.simple-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 50px;
  max-width: 150px;
  margin: 8px 8px 8px 0;
  padding: 8px 14px;
  border-radius: 4px;
  border: solid 1px #000584;

  &.clickable {
    cursor: pointer;
  }

  span {
    margin-right: 8px;
    font-family: $overline10;
    font-size: 12px;
    line-height: 1.75;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 10px;
    height: 10px;
    margin-left: 8px;
    cursor: pointer;
  }
}
