@import './../../../styles/common';

$physicalCardContentColor: get-color($bg, 100);

.card-preview {
  position: relative;
  width: 31.6rem;
  height: 21rem;
  background: $white;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.193373);
  border-radius: 8px;
  margin-top: .5rem;
  margin-bottom: 2.1rem;
  text-rendering: geometricPrecision;

  @keyframes flashes {
    from, to   {opacity: 1}
    25%, 75%  {opacity: .5}
  }

  .animated {
    animation: flashes .5s ease-in-out 1;
  }

  .card-logo {
    width: 8.6rem;
    height: auto;
    margin-top: 2.5rem;
    margin-left: 2.9rem;
  }

  .card-title {
    @include base-text($sub18, 1.4rem, 1.6rem, get-color($text, 800));
    position: absolute;
    right: 2.2rem;
    margin-top: 2.5rem;
  }

  .card-pan > div {
    width: 100%;
  }

  .card-pan {
    @include base-text($title, 2.3rem, 2.6rem, get-color($text, 700));
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 3.2rem 2.4rem 1rem;

    span {
      margin-right: 1.6rem;
      width: 5.7rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .card-secure-info {
    position: relative;
    margin-left: 2.4rem;
    margin-bottom: 1.1rem;
    display: flex;

    .card-date {
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: 1.3rem;
      }

      &--label {
        @include base-text($body12, .7rem, .9rem, get-color($text, 600));
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
      }

      &--value {
        @include base-text($title, 1.6rem, 2rem, get-color($text, 700));
        letter-spacing: .13rem;
        position: relative;
      }
    }

    .card-cvv {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 1.7rem;

      &--value {
        @include base-text($title, 1.6rem, 2rem, get-color($text, 700));
        letter-spacing: .13rem;
        position: relative;
      }
    }
  }

  .card-brand-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 2.4rem;
    margin-right: 2.2rem;

    &.marqeta-card {
      justify-content: space-between;
    }

    .card-marqeta-info {
      display: flex;
      flex-direction: column;

      &__title {
        @include base-text($overline10, .7rem, .9rem, null);
        margin-bottom: .4rem;
      }

      &__desk {
        @include base-text($body12, .7rem, .9rem, get-color($text, 700));
      }
    }

    .card-brand {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 7rem;
      height: 3.9rem;

      svg {
        width: 100%;
        height: 100%;
        path {
          fill: #1c3076;
        }
      }
    }
  }

  &__wrapper {
    #copy-button_number,
    #copy-button_cvv,
    #copy-button_date {
      width: 1.2rem;
      height: 1.4rem;
      background-color: transparent;
      border: none;
      position: absolute;
      z-index: 2;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    #copy-button_number {
      // position the same as copy-icon
      top: -1.6rem;
      right: -.8rem;
    }

    #copy-button_cvv,
    #copy-button_date {
      // position the same as copy-icon
      top: .2rem;
      right: 0;
    }

    .card-pan {
      &__number {
        &_mask {
          flex-wrap: nowrap;
          display: flex;

          span {
            margin-bottom: .6rem;
            margin-right: .7rem;
            font-family: sans-serif;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        &_show-data {
          display: none;

          .animated {
            animation: flashes .5s ease-in-out 1;
          }
        }

        #copy-button_number {
          display: none;
        }

        iframe {
          height: 2.7rem;
          width: 100%;
          box-sizing: border-box;
        }
      }

      &.visible {
        .card-pan__number {
          &_mask {
            display: none;
          }

          &_show-data, #copy-button_number {
            display: block;
          }
        }
      }
    }

    .card-secure-info {
      .card-cvv,
      .card-date {
        &__number {
          margin-right: 1.4rem;

          &_mask, &_show-data {
            height: 2rem;
          }

          &_mask {
            font-family: sans-serif;
            position: relative;
            top: .2rem;
            display: block;
            margin-right: 1.35rem;
          }

          &_show-data {
            display: none;
          }
        }

        #copy-button_cvv, #copy-button_date {
          display: none;
        }

        iframe {
          height: 1.9rem;
          width: 100%;
          margin-top: .2rem;
        }

        &.visible {
          .card-cvv__number,
          .card-date__number {
            &_mask {
              display: none;
            }

            &_show-data {
              display: block;
            }
          }

          #copy-button_cvv,
          #copy-button_date {
            display: block;
          }
        }
      }

      .card-cvv {
        &__number {
          &_show-data {
            width: 3rem;
          }
        }
      }

      .card-date {
        &__number {
          &_show-data {
            width: 4.5rem;
          }
        }
      }
    }
  }

  &.physical {
    background: #000000;

    .card-logo {
      .cls-6 {
        fill: $physicalCardContentColor;
      }
    }

    .card-title,
    .card-pan {
      color: $physicalCardContentColor;
    }

    .card-secure-info {
      .card-date {
        &--label,
        &--value {
          color: $physicalCardContentColor;
        }
      }

      .card-cvv {
        &--value {
          color: $physicalCardContentColor;
        }
      }
    }

    .card-brand-info {
      .card-marqeta-info {
        &__title,
        &__desk {
          color: $physicalCardContentColor;
        }
      }

      .card-brand {
        svg {
          path {
            fill: $physicalCardContentColor;
          }
        }
      }
    }
  }
}

.credits-description-tooltip {
  &--wrapper {
    position: relative;
    //width: 0rem;

    &__content {
      position: absolute;
      width: 6rem;
      margin: -1.5rem 0 0 5.8rem;
      z-index: 4;

      div {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 2.4rem;
        padding: 1rem 2rem;
        border-radius: 8px;
        background: $white;
        box-shadow: 0 2px 4px 3px rgba(0, 0, 0, 0.1);
        text-align: center;

        span {
          @include base-text($title18, 1.2rem, 1.4rem, get-color($text, 800));
        }
      }

      div:after {
        @include tooltipContentAfter;
      }
    }
  }
}
