@import 'src/styles/common';

@mixin newItemRow{
  display: flex;
  border-radius: 0.2em;
  background: get-color($primary, 100);
}

@mixin newItemRowButton {
  white-space: nowrap;
  font-family: $title18;
  font-size: 1.2em;
  line-height: 1.4em;
  color: get-color($primary, 800);
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown-search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 10px;

  &.disabled {
    .dropdown-search__input{
      cursor: default;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 2em;
    padding: 1.2em 1em 1.2em 1.5em;
    border-radius: 0.5em;
    background: #f8f8f8;
    cursor: pointer;

    input {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;
      font-family: $title40;
      font-size: 1.4em;
      line-height: 2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border: none;
      outline: none;
      color: get-color($text, 800);
      background: #f8f8f8;
    }

    span {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;
      font-family: $title40;
      font-size: 1.4em;
      line-height: 2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: get-color($text, 800);
    }
  }

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 3.5em;
    padding: 0.8em 1em;
    top: 0;
    margin-top: 5em;
    overflow-y: auto;
    overflow-x: hidden;
    border: 0.1em solid get-color($bg, 200);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    background-color: $white;
    z-index: 2;
    box-sizing: border-box;

    &.simplebar{
      padding: 0.8em 0 .8em 1em;

      .search-item {
        &__row {
          width: calc(100% - 3.4em);

          .status-mark {
            margin-left: .8rem;
          }
        }
      }

      .new-item__row {
        margin-right: 1em;
      }
    }

    .new-item__row {
      @include newItemRow;
      justify-content: space-between;
      padding: 1em 1.2em;

      span {
        font-family: $title18;
        font-size: 1.2em;
        line-height: 1.6em;
        color: $black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      button {
        @include newItemRowButton;
      }
    }

    .new-recipient__row {
      @include newItemRow;
      justify-content: center;
      margin-right: 1rem;

      &.disabled {
        background: get-color($bg, 200);

        button {
          color: get-color($text, 700);
          cursor: default;
        }
      }

      button {
        @include newItemRowButton;
        height: 3.6rem;
        width: 100%;
      }
    }

    .divider__horizontal {
      margin: 0.6em 0 0.9em 0;
    }

    .search-item {
      &__title {
        margin-left: 1.3em;
        margin-bottom: 0.2em;
        font-family: $body12;
        font-size: 1em;
        line-height: 1.5em;
        color: get-color($text, 700);
      }

      &__row {
        display: flex;
        align-items: center;
        width: calc(100% - 1.4em);
        min-height: 1.6em;
        padding: 0.5em 0 0.5em 1.3em;
        font-family: $title18;
        font-size: 1.2em;
        line-height: 1.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;

        .status-mark {
          margin-left: .8rem;
        }

        span {
          color: get-color($text, 600);

          &.highlighted {
            color: #000000;
          }
        }

        &:hover {
          background-color: get-color($primary, 100);
        }
      }
    }
  }
}
