@import "src/styles/common";

.label {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: .8rem;
  @include base-text($title18, 1.4rem, 1.6rem, get-color($text, 700));

  &.disabled {
    opacity: .4;
  }

  svg {
    margin-left: .2rem;
    cursor: pointer;
  }

  &__info-tooltip {
    position: absolute;
    width: 19.6rem;
    transform: translate(-46%, -118%);
    // TODO z-index is 1000001 due to overlay-container z-index is 1000000
    z-index: 1000001;

    div {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 2.4rem;
      padding: .8rem;
      border-radius: 8px;
      background: $white;
      box-shadow: 0 2px 4px 3px rgba(0, 0, 0, 0.1);
      text-align: center;

      span {
        font-family: $body12;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: get-color($text, 800);
      }
    }

    div:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: $white;
      border-bottom: 0;
      margin-left: -1rem;
      margin-bottom: -1rem;
    }
  }
}


