@import './../../../styles/common';

.limit-info {
  width: 100%;

  &--editable {
    display: flex;
    align-items: center;

    span {
      font-family: $title40;
      font-size: 14px;
      line-height: 1.59;
      padding-right: 2px;
    }

    .limit-info__input {
      outline: none;
      border: none;
      font-family: $title40;
      font-size: 1.4rem;
      line-height: 2;
      padding: 0;
      max-width: 6.5rem;
      @include lineClamp(1);

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  &__credits {
    font-family: $caption10;
    justify-content: flex-start;
    align-items: flex-start !important;

    svg {
      min-width: 1.5rem;
    }
  }

  .limit-header--row {
    display: flex;
    align-items: flex-start;

    span:first-child {
      margin-right: auto;
    }

    span {
      color: get-color($text, 900);
      font-family: $body12;
      font-size: 1.2rem;
      line-height: 1.8rem;
      padding-bottom: .4rem;
    }

    .limit-info__credits {

      span {
        padding-bottom: 0;
      }
    }

    .exchange-data {
      position: relative;
      display: flex;
      align-items: center;

      &__info--icon {
        width: 14px;
        height: 14px;
        margin: 0 0 3px 3px;
      }

      &__tooltip {
        position: absolute;
        width: 180px;
        margin: -85px 0 0 -81px;
        z-index: 3;

        @include tooltipCommon;
      }
    }
  }

  &__value {
    font-family: $sub18;
    color: get-color($text, 900);
  }

  .limit-credits-info {
    z-index: 2;
    position: fixed;
    width: 114px;
    height: 48px;
    padding: 10px 20px;
    margin-top: -76px;
    margin-left: -32px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;

    &__item {
      span {
        font-family: $body12;
        font-size: 12px;
        line-height: 18px;
        color: get-color($text, 900);

        &.bold {
          font-family: $overline10;
        }
      }
    }
  }
}
