@import './../../../styles/common';

@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.lds-eclipse {
  position: relative;
}
.lds-eclipse div {
  position: absolute;
  -webkit-animation: lds-eclipse 1s linear infinite;
  animation: lds-eclipse 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 get-color($primary, 900);
  -webkit-transform-origin: 80px 82px;
  transform-origin: 80px 82px;
}

.loader-small {
  width: 16px;
  height: 16px;
  right: -22px;
  top: -3px;
  position: absolute;
  -webkit-animation: lds-eclipse 1s linear infinite;
  animation: lds-eclipse 1s linear infinite;
  border-radius: 50%;
  box-shadow: 0 1px 0 0 get-color($primary, 900);
}

.lds-eclipse {
  width: 70px !important;
  height: 70px !important;
  -webkit-transform: translate(-35px, -35px) scale(0.35) translate(35px, 35px);
  transform: translate(-35px, -35px) scale(0.35) translate(35px, 35px);
}

.centered-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: $white;
  top: 0;
}
