@import 'src/styles/common';

.step-container {
  position: relative;
  height: 2.4rem;

  .description-container {
    position: absolute;
    bottom: -1.6rem;
    left: 1.2rem;
    width: 16rem;
    transform: translate(-50%, 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    &__label {
      font-size: 1.4rem;
      line-height: 2rem;
      font-family: $title18;
      color: get-color($text, 800);
    }
  }
}
