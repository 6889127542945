@import '../../../styles/common';

.wallet-overlay {
  position: absolute;
  background: $white;
  bottom: 2.4rem;
  right: 2rem;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 0 15px;
  padding: 2rem 1.6rem;
  z-index: 11;
  @include base-text($body14, 1.4rem, 2.1rem, get-color($text, 900));

  &__header {
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid get-color($text, 300);
  }

  &__list-options {
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 2.4rem;
    list-style: none;
    counter-reset: li;

    li {
      display: inline-flex;
      counter-increment: li;
      margin-bottom: 1.8rem;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: counter(li) '. ';
        margin-right: 1rem;
        color: get-color($text, 900);
        font-family: $title14;
      }
    }

    &.disabled {
      font-size: 1.4rem;
      padding-left: 0;
      list-style-type: none;

      li::before {
        content: none;
      }
    }

    .wallet-emails {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        flex-direction: column;

        &:first-child {
          margin-bottom: 1rem;
        }

        span {
          @include base-text($body14, 1.4rem, 2.1rem, get-color($text, 900));
          padding: 0;
        }

        .wallet-emails__title {
          font-family: $title14;
          font-size: 16px;
        }
      }

    }
  }
}
