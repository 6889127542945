@import '~tribal-color-styles';
@import 'colors.module';
@import 'functions';
@import 'variables';
@import 'mixins';
@import 'auth-mixins';

@mixin emptyTransactionBody {
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-family: $sub18;
      font-size: 18px;
      line-height: 24px;
      color: get-color($text, 600);
    }
  }

  &__button-wrapper {
    margin-top: 10px;
  }
}
