@import '../../../styles/common';

.default-input__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  padding: 12px 10px 12px 15px;
  border-radius: 5px;
  background: get-color($bg, 100);

  &.disabled {
    opacity: 0.4;
    cursor: default !important;
  }

  &.invalid {
    border: 1px solid get-color($error, 600);

    span {
      font-family: $body12;
      font-size: 12px;
      color: get-color($error, 600);
    }
  }

  input {
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: $title40;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: none;
    outline: none;
    color: get-color($text, 800);
    background: get-color($bg, 100);
  }
}
