@import './../../../../styles/common';

.add-department-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 2.3em;
  height: 2.3em;
  padding: 0.5em 0 0.75em 0;
  background-color: #ecf5fe;

  &__left-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    height: 100%;
    margin-left: 5%;

    .description {
      font-size: 0.6em;
      font-family: $body12;
      line-height: 1.92;
      color: #9e9e9e;
    }

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 0.8em;
      font-family: $title40;
      line-height: 1.88;
      color: #000000;
    }
  }

  &__right-column {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 30%;
    height: 100%;
    margin-right: 5%;

    span {
      font-size: 0.6em;
      font-family: $body12;
      text-align: right;
      text-transform: uppercase;
      color: #000584;
      cursor: pointer;
    }
  }
}
