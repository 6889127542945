@import './../../../../styles/common';

.departments-dropdown__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  margin-top: 2.1em;
  width: 100%;
  max-width: 41.5rem;
  min-height: 3.5rem;
  max-height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  border: 1px solid  get-color($bg, 100);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  z-index: 2;

  .simplebar-content {
    display: flex;
    flex-direction: column;
  }
}
