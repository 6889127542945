@import 'src/styles/common';

.nav-item {
  $transparent-border: 3px solid $transparent;
  $expandable-border: 3px solid get-color($primary, 700);
  $full-scale: scaleX(1);

  box-sizing: border-box;
  border-top:  $transparent-border;
  border-right:  $transparent-border;
  border-left:  $transparent-border;
  cursor: pointer;

  &:after {
    display:block;
    content: '';
    border-bottom: $expandable-border;
    transform: scaleX(0);
    transition: transform .25s ease-in-out;
    cursor: pointer;
  }

  &:hover:after {
    transform: $full-scale;
  }

  &.selected {
    &:after {
      transform: $full-scale;
    }

    .nav-item__label {
      color: get-color($text, 900);
    }
  }

  &__label {
    display: inline-block;
    margin: .8rem;
    @include base-text($publicSansBold, 1.6rem, 1.9rem, get-color($text, 700));
  }
}
