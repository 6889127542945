@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    //640px
    @media (max-width: 40em) {
      @content
    }
  };
  @if $breakpoint == tab-port {
    //767px
    @media (max-width: 47.94em) {
      @content
    }
  };
  @if $breakpoint == small-tab {
    //850px
    @media (max-width: 53.12em) {
      @content
    }
  };
  @if $breakpoint == tab-land {
    //1023px
    @media (max-width: 63.94em) {
      @content
    }
  };
  @if $breakpoint == desktop {
    //1280px
    @media (max-width: 80em) {
      @content
    }
  }
  @if $breakpoint == big-desktop {
    //1440px
    @media (max-width: 90em) {
      @content
    }
  }
  @if $breakpoint == large-desktop {
    //1440px
    @media (min-width: 90em) {
      @content
    }
  };
}

@mixin flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin base-text($font-family, $font-size, $line-height, $color: $black) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

@mixin lineClamp ($lineQuantity) {
  overflow: hidden;
  text-overflow: ellipsis;

  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lineQuantity;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-clamp: $lineQuantity;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin sentence-case() {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

@mixin noInputAutocompleteBackground($color: $white) {
  filter: none;
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
      -webkit-box-shadow: 0 0 0 1rem $color inset !important;
    }

    &:first-line {
      font-size: 1.6rem;
      font-family: $sub18;
    }
  }
}

@mixin headerLogo {
  width: 10.3rem;
  height: 3.6rem;
}

@mixin commonContentMargin {
  margin: 0 4rem;

  @include respond(phone) {
    margin: 0 3rem;
  }
}

@mixin pageWrapperPosition {
  /*
   * width of the current selector is equal to  9 / 12 ( 75 % ) of the grid container.
   * sidebar area width is equal 3 / 12 ( 25 % ) of the grid container.
   * 100% width of the grid container in the current selector is equal to current width increased by 25% ( coefficient 1.25 ).
   * width of the sidebar is equal to 25% * 1.25 in the current selector.
   * sidebar-margin-left -- width difference between 3/12 of the grid container and sidebar width.
   */
  $coefficient-sidebar: 1.25;
  $sidebar-area-width: calc(25% * #{$coefficient-sidebar});
  $sidebar-margin-left: calc(#{$sidebar-area-width} - #{$sidebar-width});

  width: calc(100% - #{$sidebar-margin-left});
  max-width: 110rem;

  @include respond(desktop) {
    margin-right: 4rem;
    width: auto;
  }
}

@mixin emptyTransactionBody {
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-family: $sub18;
      font-size: 18px;
      line-height: 24px;
      color: get-color($text, 600);
    }
  }

  &__button-wrapper {
    margin-top: 10px;
  }
}

@mixin tooltipContentAfter {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: $white;
  border-bottom: 0;
  margin-left: -1rem;
  margin-bottom: -.9rem;
}


@mixin linkAdd {
  &--add {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: get-color($primary, 800);
    width: 2.9rem;
    height: 2.9rem;
    margin-left: 1.2rem;

    img {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
}

@mixin pageContentHeader {
  &__label {
    font-size: 2.2rem;
    font-family: $title40;
    line-height: 2.6rem;
    color: $black;

    @include respond(phone) {
      font-size: 2.4rem;
      font-family: 'WorkSansBold', sans-serif;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      font-family: $title18;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: $black;
    }

    @include linkAdd;
  }
}

@mixin pageSortBy {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  cursor: pointer;

  span {
    font-family: $body14;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: get-color($text, 700);
  }
  .selected-option {
    font-family: $title18 !important;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding-left: .8rem;
    padding-right: .8rem;
    color: get-color($text, 900);
  }

  img {
    transform: rotate(90deg);
    height: 1.1rem;
    width: 1.1rem;
  }

  &.active {
    img {
      transform: rotate(-90deg);
    }
  }
}

@mixin pageHeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  &:last-child {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
  }

  .sort-by {
    @include pageSortBy;

    .sort-suggestions {
      position: absolute;
      min-width: 10rem;
      top: 2.4rem;
      padding: 1.6rem;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid  get-color($bg, 200);
      display: flex;
      flex-direction: column;
      z-index: 4;

      span {
        font-family: $body14;
        font-size: 1.2rem;
        line-height: 2.4rem;
        color: get-color($text, 800);

        &.active {
          color: get-color($primary, 800);
        }
      }
    }
  }

  @include respond(phone) {
    .sort-by {
      margin-left: 1rem;
    }

    .search-autocomplete {
      display: flex;
      flex: 1;
    }

    input {
      width: 100%;
    }
  }
};

@mixin cardCurrencyBlock {
  .card-currency-block {
    display: flex;
    align-items: center;
    margin-right: 2.6rem;

    img {
      width: 2rem;
      height: 1.5rem;
      border-radius: 2px;
    }

    span {
      margin-left: .8rem;
      font-size: 1.2rem;
      font-family: $body12;
      line-height: 1.8rem;
      color: get-color($text, 600);
    }
  }
}

@mixin pagePaddingTop {
  padding-top: 4.5rem;

  @include respond(phone){
    padding-top: 2rem;
  }
};

@mixin imageActive {
  margin-left: 1rem;

  &.active {
    transform: rotate(180deg);
  }
};

@mixin spendingHeaderSortByOnPhone {
  &--header{
    .header-row{
      .sort-by {
        display: none;
      }}
  }
};

@mixin commonTooltipFont {
  font-family: $title18;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

@mixin tooltipCommon {
  div {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem .8rem 1rem;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 2px 4px 3px rgba(0, 0, 0, 0.1);
    text-align: center;

    .rate {
      @include commonTooltipFont;
      color: get-color($text, 800);
    }

    .date {
      @include commonTooltipFont;
      color: get-color($text, 500);
    }

    span {
      @include commonTooltipFont;
      color: get-color($text, 800);
    }
  }

  div:after {
    @include tooltipContentAfter;
  }
}

@mixin lineClamp ($lineQuantity) {
  overflow: hidden;
  text-overflow: ellipsis;

  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lineQuantity;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-clamp: $lineQuantity;
}


@mixin statusLabelForLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;
  margin-left: .8rem;
  padding: .4rem;
  box-sizing: border-box;
  border-radius: 5px;
  white-space: nowrap;


  span {
    font-family: $overline10;
    line-height: 1.5;
    font-size: 1rem;
  }
}

@mixin commonPageHeader {
  width: 100%;
  margin-bottom: 30px;
  span {
    font-family: $title30;
    font-size: 30px;
    line-height: 40px;
    color: get-color($text, 900);
  }
}

@mixin rewardsHeaderLabel {
  font-size: 2.4rem;
  line-height: 3rem;
  color: $black;
  font-family: $title30;
  margin-bottom: .8rem;
}

@mixin rewardsHeaderSubLabel {
  font-size: 1.6rem;;
  line-height: 2.2rem;
  color: get-color($text, 800);
  font-family: $sub18;
}

@mixin emptyTransactions {
  display: flex;
  flex-direction: column;
  width: 100%;

  .empty-transactions {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8.4rem;

      span {
        font-family: $sub12;
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: get-color($text, 400);
      }
    }

    @include emptyTransactionBody;
  }
}

@mixin expandedValuesMenu {
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .header-container {
    display: flex;
    align-items: flex-start;

    &.block {
      span {
        max-width: 100%;
        word-break: break-all;
      }
    }

    span {
      display: block;
      max-width: 21rem;
      margin-right: .8rem;
      font-family: $title14;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: get-color($text, 900);
    }

    svg {
      margin-top: .6rem;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .menu-container {
    display: flex;
    flex-direction: column;
    margin-top: .5rem;

    .subtitle {
      margin: 1rem 0 .5rem 0;
      font-family: $title14;
      font-size: 1.8rem;
      line-height: 2.1rem;
    }

    .value {
      display: block;
      font-family: $body14;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: get-color($text, 700);
    }
  }
}

@mixin cardLoading {
  .card-loading {
    position: absolute;
    width: 100%;
    height: 7.8rem;
    background-color: $white;
    opacity: 0.7;

    .loader-container {
      padding-top: 0;
    }
  }
}

@mixin expandedIcon($deg) {
  transition: .1s all ease;

  &.expanded {
    transition: .1s all ease;
    transform: rotate($deg);
  }
}

@mixin modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

@mixin mediumLoader {
  .lds-eclipse {
    width: 30px !important;
    height: 30px !important;
    transform: translate(-36px, -24px) scale(0.35) translate(20px, 20px) !important;
    div {
      width: 80px;
      height: 80px;
      transform-origin: 40px 42px;
    }
  }
}

@mixin balanceCircle($z_index, $stroke ) {
  z-index: $z_index;

  .balance-circle {
    stroke: $stroke;
    fill: transparent;
  }
}


@mixin formInputContainerMixin {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

@mixin formInputContentMixin {
  display: flex;
  align-items: center;
  padding: .5rem 2rem .5rem 1.5rem;
  background: get-color($bg, 100);
}

@mixin formInput {
  width: 100%;
  padding: .7rem 0;
  margin: 0;
  font-family: $title40;
  font-size: 1.4rem;
  line-height: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: none;
  outline: none;
  color: get-color($text, 900);
  background: get-color($bg, 100);
}

@mixin categoryActions {
  &:hover {
    background: get-color($primary, 100);
    border: 1px solid get-color($primary, 100);
  }

  &:active {
    background: get-color($primary, 200);
    border: 1px solid get-color($primary, 200);
  }

  &.highlighted {
    background: get-color($primary, 100);
    border: 1px solid get-color($primary, 700);
  }
}

@mixin iconSize($width, $height:$width){
  width: $width;
  min-width: $width;
  height: $height;
  min-height: $height;
}

@mixin inputFlagStyles {
  width: 2.7rem;
  height: 2rem;
  margin-right: 1rem;
  border-radius: 2px;
}

@mixin inputCurrencyCodeStyles {
  @include base-text($publicSansSemiBold, 1.4rem, 1.6rem, get-color($text, 900));
  text-transform: uppercase;
}
