@import './../../../styles/common';

.button {
  padding: .5rem 1rem;
  font-family: $overline10;
  color: get-color($bg, 900);
  font-size: 1.4rem;
  line-height: 2rem;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 1;
  transition: .5s opacity ease-in-out;

  &.disabled {
    transition: .5s opacity ease-in-out;
    opacity: .5;
  }

  &.outline {
    border: 1px solid get-color($text, 900);
    border-radius: 5px;
  }
}
