@import './../../../../styles/common';

.department-row {
  width: 90%;
  min-height: 1.5em;
  margin: 0 5% 0 5%;
  padding: 0.25em 0 0.25em 0;
  font-family: $body12;
  font-size: 0.8em;
  line-height: 2.33;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #000000;
  cursor: pointer;

  &.selected {
    color: #000584;
  }
}
