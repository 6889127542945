@import "src/styles/common";

.notification-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: .6rem .4rem .4rem .6rem;
  border-left: 3px solid;
  padding: 1.2rem 0;
  margin-bottom: 3rem;

  @include pageWrapperPosition;


  &__title {
    font-family: $body14;
    font-size: 1.8rem;
    line-height: 2rem;
    color: get-color($text, 900);
  }

  @include respond(tab-land) {
    margin: 0 4rem 3rem 4rem;

    @include respond(phone) {
      margin: 0 3rem 3rem 3rem;

      &__title {
        font-family: $notificationTitle;
        font-size: 1.6rem;
      }
    }
  }

  &__info-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0 2rem;
  }

  &--overdue {
    background: get-color($error, 100);
    border-color: get-color($error, 600);
  }

  &--special {
    max-width: 65rem;
    background: #F8FFFB;
    border: 2px solid get-color($secondary, 800);
    border-radius: 6px;
    padding: 1.5rem 0;

    .notification-banner__content-wrapper {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .notification-banner__title {
      display: flex;
      align-items: center;
      font-family: $sub18;
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-right: 2.2rem;
      color: get-color($secondary, 800);

      &--migration {
        font-family: $body14;

        a {
          color: get-color($secondary, 800);
        }
      }
    }

    .notification-button {
      margin: 0 2.4rem 0 auto;
    }


    .close-icon {
      margin-left: 1rem;
    }

    @include respond(tab-port) {
      .notification-banner__info-container {
        margin: .8rem 2rem;
      }

      .notification-banner__content-wrapper {
        .notification-banner__title {
          font-size: 1.6rem;
          margin-right: 1rem;
        }
      }
    }


    @include respond(phone) {
      min-height: auto;
      padding: 0;

      .notification-banner__content-wrapper {
        flex-direction: column;
        align-items: flex-start;
        .notification-banner__title {
          font-size: 1.5rem;
        }

        .notification-button {
          margin: .8rem 0 0 3.8rem;
        }
      }
    }


    .notification-banner__info-container {
      .close-icon {
        margin-left: 0;
      }
    }
  }

  &--due_tomorrow {
    background: get-color($warning, 100);
    border-color: get-color($warning, 400);
  }

  &--policy {
    background: get-color($bg, 100);
    border-color: #309597;
  }

  .lighting-icon,
  .close-icon {
    cursor: pointer;
    margin-left: 1rem;
    min-width: 2.2rem;
    min-height: 2.2rem;
  }

  .lighting-icon {
    margin-right: 1.6rem;
    margin-left: 0;
  }
}
