@import 'src/styles/common';

.amount-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &.disabled {
    opacity: .4;
  }

  &__content {
    display: flex;
    align-items: center;
    padding: 1.2rem 3.4rem 1.2rem 1.6rem;
    background: get-color($bg, 100);
    box-sizing: border-box;
    border-radius: 5px;

    &.invalid {
      border: 1px solid get-color($error, 600);
    }

    img {
      @include inputFlagStyles;
    }

    .currency-code {
      @include inputCurrencyCodeStyles;
    }

    input {
      width: 100%;
      margin: 0 0 0 .8rem;
      @include base-text($title40, 1.4rem, 2rem, get-color($text, 900));
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border: none;
      outline: none;
      background: get-color($bg, 100);
    }

    .value-description {
      display: flex;
      flex-shrink: 0;
      margin-left: 1rem;
      @include base-text($publicSansNormal, 1.2rem, 1.4rem, get-color($text, 500));
    }
  }

  &__description {
    display: flex;
    align-items: center;
    margin: .8rem 0 0 1.5rem;
    @include base-text($title40, 1.2rem, 1.6rem, get-color($text, 600));
  }

  &__info-icon {
    position: absolute;
    @include iconSize(1.2rem);
    top: 1.6rem;
    right: 1.7rem;
    cursor: pointer;
  }
}
