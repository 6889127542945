@import 'mixins';
@import 'functions';
@import 'variables';

@mixin authFormContainer {
  display: flex;
  flex-direction: column;
  min-height: 30rem;
}

@mixin authFormTitle {
  line-height: 1.24;
  font-size: 3rem;
  font-family: $title30;
  word-break: break-word;

  @include respond(phone) {
    font-size: 2.4rem;
  }
}

@mixin authFormActionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @include respond(phone){
    margin: 0;
  }
}

@mixin authFormSubmitDisabled {
  opacity: .2;
}

@mixin authFormSubmit {
  color: get-color($primary, 800);
  font-family: $button16;
  text-transform: uppercase;
  border: none;
  font-size: 1.6rem;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  white-space: pre-wrap;
  padding-right: 1rem;

  &--container {
    display: flex;
    margin-top: 1.5rem;
  }

  &:disabled {
    @include authFormSubmitDisabled;
    &--container {
      margin-top: .5rem;
    }
  }
}

@mixin authFormSmallActionButton {
  color: get-color($primary, 800);
  font-size: 1.2rem;
  font-family: $button16;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    @include authFormSubmitDisabled;
  }
}

@mixin authFormTitle__phone {
  width: 100%;
  font-size: 2.4rem;
}


@mixin authFormInputContainer__phone {
  margin-top: 1rem;
  width: 100%;
}

@mixin authFormContainer__phone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin authFormInputLabel__phone {
  flex-direction: column;
  align-items: baseline;
  width: 100%;
}

@mixin authFormInputLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $body12;
  color: get-color($text, 800);
  font-size: 1.6rem;
}

@mixin authFormError {
  color: get-color($error, 500);
  font-size: 1.2rem;
  line-height: 1.6;
  font-family: $body12;
  text-align: right;
  min-height: 2rem;
  max-width: 29rem;
  margin-bottom: .5rem;

  &--box {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@mixin authFormCommonSectionMargin {
  @include respond(tab-port){
    margin-bottom: 15rem;
  }
  @include respond(phone){
    margin-bottom: 20rem;
  }
}

@mixin AuthFormInput {
  min-width: 29rem;
  height: 3.6rem;
  border: 1px solid get-color($bg, 500);
  border-radius: 4px;

  @include respond(phone){
    min-width: 20rem;
  }
}

@mixin authFormInputWithInfoBlock {
  &-container {
    margin-top: 4rem;
  }

  &--label {
    @include authFormInputLabel;
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
}

@mixin authInput {
  border: none;
  outline: none;
  font-family: $sub18;
  font-size: 1.6rem;
}
