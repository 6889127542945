@import 'src/styles/common';

.chevron {
  $iconSize: 2rem;
  width: $iconSize;
  height: $iconSize;
  min-width: $iconSize;
  min-height: $iconSize;

  &.disabled {
    path {
      fill: get-color($text, 400);
    }
  }

  &.left {
    transform: rotate(-180deg);
  }
}
