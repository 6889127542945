@import 'src/styles/common';

.resend-link {
  &__text-wrapper {
    position: relative;
  }

  &__text {
    font-family: $caption10;
    font-size: 10px;
    color: get-color($primary, 800);
    cursor: pointer;
    transition: 0.3s all ease-in-out;

    &.pending {
      opacity: 0.4;
      transition: 0.3s opacity ease-in-out;
    }

    &.resolved {
      cursor: default;
    }
  }
}
