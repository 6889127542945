@import "src/styles/common";

.input-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 39.5rem;

  .default-input__wrapper {
    width: auto;
  }

  &.auto-width {
    max-width: initial;
  }

  .dropdown-search__input {
    span {
      color: get-color($text, 900);
    }
  }

  .description-block {
    display: flex;
    flex-direction: column;
    margin-top: .8rem;

    &__item {
      @include base-text($publicSansNormal, 1.2rem, 1.4rem, get-color($text, 500));

      &:not(:first-child) {
        margin-top: .4rem;
      }
    }
  }

  &__description {
    @include base-text($publicSansNormal, 1.2rem, 1.4rem, get-color($text, 500));
    margin-top: .8rem;
  }

  &__error {
    @include base-text($body12, 1.2rem, 1.4rem, get-color($error, 600));
    text-align: right;
  }
}
