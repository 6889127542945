@import 'src/styles/common';

.search-modal {
  max-width: 57.6rem;
  min-height: 70rem;
  padding: 2rem;
  background-color: $white;
  border: 1px solid get-color($text, 200);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  box-sizing: border-box;
  width: 100%;

  &.portal {
    margin: 3rem;
    max-height: calc(100vh - 3rem);
    position: absolute;
    z-index: 999999;
  }


  @include respond(phone) {
    max-width: calc(100vw - 3rem);
  }

  &__title {
    font-size: 2.2rem;
    line-height: 3rem;
    font-family: $title18;
  }

  &__divider {
      border-color: get-color($bg, 300);
      margin: 2rem 0;
  }


  &__items-section {
    max-height: 50rem;
    padding-right: .5rem;
    padding-left: .5rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.common-scroll {
      overflow: hidden auto;
    }

    .search-item {
      background-color: $white;
      border-radius: 4px;
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &.simplebar {
        margin-right: 1rem;
      }

      &:hover {
        background-color: get-color($primary, 100);
      }

      &__label {
        color: get-color($text, 800);
        font-size: 1.6rem;
        line-height: 2rem;
        font-family: $title14;
        margin-bottom: 1rem;
      }

      &__description {
        color: get-color($text, 700);
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-family: $sub18;
      }

      &__label,
      &__description {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}
