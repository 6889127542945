@import './../../../styles/common';

.available-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  color: get-color($text, 900);

  span {
    &:first-child {
      font-family: $caption10;
      font-size: 1.2rem;
      line-height: 1.25;
    }
    &:last-child {
      font-family: $title18;
      font-size: 1.4rem;
      line-height: 2.13;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.small {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 18.6rem;
    height: 3.4rem;
    padding: .5rem 1.4rem .1rem 1.2rem;
    color: $black;
    background-color: get-color($primary, 100);

    .available-box__total{
      margin-right: .4rem;
    }

    span {
      &:first-child {
        font-family: $fontRegular;
        font-size: 1.2rem;
        height: 3.4rem;
        width: auto;
      }
      &:last-child {
        max-width: 13rem;
        font-family: $fontBold;
        font-size: 1.6rem;
        line-height: 3.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &.total {
    background-color: get-color($primary, 200);
    border: none;
    font-family: $caption10;

    span {
      &:last-child {
        font-family: $body12;
        font-size: 3rem;
        line-height: 1.25;
      }
    }
  }

  &__value {
    max-width: 16rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--big {
    display: flex;
    justify-content: space-evenly;
    min-width: 15rem;
    padding: 1.2rem 2rem;
    height: 6.2rem;
  }

  &--medium {
    width: 10.5rem;
    height: 4.5rem;
    padding: 1.6rem .8rem 1rem;
    background-color: get-color($primary, 200);
    border: none;

    span {
      color: get-color($text, 900);
      &:first-child {
        font-family: $body12;
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding-bottom: .2rem;
      }
      &:last-child {
        font-family: $title18;
        font-size: 1.8rem;
        line-height: 2.4rem;
        max-width: 100%;
      }
    }
  }

  &__total {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    font-family: $caption10 !important;
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
  }
}
