@import 'src/styles/common';

.step-line {
  &.pending {
    border-bottom: 3px solid get-color($text, 200);
  }

  &.complete {
    border-bottom: 3px solid get-color($secondary, 300);
  }
}
