@import 'src/styles/common';

.dropdown-box {
  @include flexCenter;
  flex-direction: column;
  padding: 1.5rem 1rem;
  background: $white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: absolute;
  bottom:0;
  right: 0;
  z-index: 999999;
  box-sizing: border-box;
  transform: translateY(calc(100% + .5rem));

  &-container {
    display: flex;
    width: 23.4rem;
    padding: 1rem 1.4rem 1rem 1rem;
    border: 1px solid transparent;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;

    @include categoryActions;

    &:not(:first-child){
      margin-top: .8rem;
    }

    &__icon {
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
      min-height: 2.4rem;
      margin-right: .8rem;
    }

    .input-info-section{
      display: flex;
      flex-direction: column;

      &__label{
        font-family: $title18;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $black;
      }

      &__description {
        font-family: $caption10;
        font-size: 1rem;
        line-height: 1.5rem;
        color: get-color($text, 500);
        white-space: normal;
      }
    }
  }
}
