@import '../../../styles/common';

.spending-header {
  display: flex;
  align-items: flex-end;

  @include respond(phone) {
    display: none;
  }

  &__spending {
    display: flex;
    flex-direction: column;
    min-width: 15rem;
    margin-bottom: 1rem;

    &--block {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 1rem;
    }

    span {
      color: $black;
      font-family: $title24;
      font-size: 2.4rem;
      line-height: 3rem;
    }
  }

  &__progress {
    .progress-tooltip {
      &__container {
        .body {
          .amount {
            font-size: 2.2rem;
            line-height: 2.6rem;
          }
        }
      }
    }
  }

  .billing-info_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    .available-box__wrapper {
      display: flex;
      justify-content: center;
      flex: 1;

      .available-box {
        margin: 0 .4rem;

        &__value {
          max-width: 20rem;
        }
      }

      @include respond(tab-port) {
        .available-box {
          &--big {
            min-width: 8.4rem;
            padding: 1rem;
            height: 4.4rem;
          }

          &__value {
            max-width: 12.5rem;
            font-size: 1.8rem !important;
            font-family: $title18 !important;
          }
        }
      }
    }
  }

  &__limits {
    display: flex;
    flex-direction: column;
    font-family: $title18;

    .limit-credits-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -9rem;
    }

    .limit-info {
      &__value {
        line-height: 3.4rem !important;
        max-width: 15.5rem !important;
      }
    }
  }

  @include respond(tab-port) {
    &__limits {
      .limit-info {
        justify-content: space-around;
        height: 4.4rem;
        padding: 0 !important;

        &__value {
          font-size: 1.8rem !important;
          line-height: 1.25 !important;
          max-width: 12.5rem !important;
        }

        .limit-credits-info {
          margin-top: -13.6rem;
        }
      }
    }
  }
}
