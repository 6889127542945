@import "../../../styles/common";

.exchange-data__tooltip {
  position: absolute;
  width: 18rem;
  transform: translate(-46%, -118%);
  z-index: 3;

  @include tooltipCommon;
}
