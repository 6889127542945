@import "src/styles/common";

.search {
  background: get-color($bg, 100);
  border-radius: 8px;
  margin-top: 2rem;
  width: 100%;
  height: 5rem;
  display: flex;

  &-input {
    background: transparent;
    border: none;
    outline: none;
    margin-right: 1rem;
    width: 100%;
    font-size: 1.6rem;
    line-height: normal;
    font-family: $sub18;
    color: get-color($text, 800);
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;

    &-loop-icon {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
    }
  }
}
