@import 'src/styles/common';

.stepper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

// replace to component
.stepper-wrapper {
  height: 10rem;
  display: flex;
  align-items: flex-start;
  width: 70%;
  margin: 7.4rem auto 0 auto;
}
