@import '../../../styles/common';

.pending-email {
  width: 330px;
  font-size: 12px;

  .title {
    font-family: $body12;
    font-size: 1em;
  }

  .description {
    font-family: $body12;
    font-size: 1em;
    color: #9e9e9e;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    width: 300px;

    button {
      padding: 0;
      font-family: $body12;
      font-size: 0.84em;
      line-height: 3;
      color: #000584;
      background-color: transparent;
      border: none;
      outline: none;
      text-transform: uppercase;
      cursor: pointer;

      &:disabled {
        opacity: 0.4;
        background-color: transparent;
      }
    }
  }
}
