@import './../../../styles/common';

.prev-next-button {
  display: flex;
  height: 30px;
  border: 1px solid get-color($bg, 900);
  border-radius: 3px 0 0 3px;
  cursor: pointer;

  .arrow {
    width: 10px;
    height: 16px;
    margin: 7px 12px;
  }

  &--next {
    border-radius: 0 3px 3px 0;
    border-left: none;

    .arrow {
      transform: rotate(180deg);
    }
  }

  .back-arrow {
    fill: get-color($bg, 900);
  }

  &:hover {
    background-color: get-color($bg, 200);
    border-color: get-color($bg, 600);

    .back-arrow {
      fill: get-color($bg, 600);
    }
  }

  &:active {
    background-color: get-color($bg, 300);
    border-color: get-color($bg, 900);

    .back-arrow {
      fill: get-color($bg, 600);
    }
  }

  &--disabled {
    border-color: get-color($bg, 500);
    cursor: default;

    .back-arrow {
      fill: get-color($bg, 500);
    }

    &:hover {
      background-color: #ffffff;
      border-color: get-color($bg, 500);

      .back-arrow {
        fill: get-color($bg, 500);
      }
    }
  }
}
