@import './../../../styles/common';


.download-transactions {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: .5s opacity ease-in-out;

  &-wrapper {
    position: relative;

    .loader-small {
      position: initial;
      margin-right: 1.2rem
    }
  }

  &.pending {
    opacity: .5;
    transition: .5s opacity ease-in-out;
  }

  &--icon {
    height: 16px;
    width: 18px;
    margin-right: 12px;

    path {
      fill: get-color($bg, 700);
    }
  }

  span {
    font-size: 12px;
    font-family: $title40;
    line-height: 14px;
    color: get-color($bg, 700);
  }
}
