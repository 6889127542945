@import './../../../styles/common';

.set-to-max {
  display: flex;
  align-items: center;
  font-family: $sub12;
  font-size: 1.2rem;
  line-height: 1.4rem;
  cursor: pointer;
  padding: .6rem 1rem;
  border-radius: 5px;

  &__icon {
    min-width: 1.4rem;
    min-height: 1.4rem;
    position: relative;
    z-index: 11;
  }

  span {
    white-space: nowrap;
    color: get-color($primary, 800);
  }

  svg {
    margin-left: .6rem;

    path {
      fill: get-color($primary, 800);
    }
  }

  &.locked {
    opacity: .25;
    pointer-events: none;
  }

  &.selected,
  &:hover {
    background: get-color($primary, 100);
  }

  &:active {
    background: get-color($primary, 300);
  }

  .exchange-data {
    position: relative;
    display: flex;
    align-items: center;

    &__info--icon {
      width: 1.4rem;
      height: 1.4rem;
      margin: 0 0 .3rem .3rem;
    }
  }
}
