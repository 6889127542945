@use "sass:math";
@import 'src/styles/common';

@mixin tableColRowCommon {
  font-family: $table;
  padding: 0 .5rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    text-align: right;
    justify-content: flex-end;
    padding-right: 0;
  }
}

.table {
  $loader-width: 7rem;
  $ordered-pagination-loader-position: calc(50% - #{math.div($loader-width, 2)});

  &-pagination {
    &__infinite-pagination-loader {
      display: flex;
      flex: 1;
      justify-content: center;
      margin-top: 1rem;

      &.medium-loader {
        @include mediumLoader;
        .lds-eclipse {
          transform: translate(-21px, -24px) scale(0.35) translate(20px, 20px) !important;
        }
      }
    }

    &__ordered-pagination-loader {
      position: absolute;
      top: $ordered-pagination-loader-position;
      left: $ordered-pagination-loader-position;
    }
  }

  &.loading {
    $transition: opacity .5s ease-in-out;
    position: relative;
    opacity: 1;
    transition: $transition;

    .table-body {
      opacity: .5;
      transition: $transition;
    }
  }

  &-header {
    display: flex;
    flex: 1;
    justify-content: space-between;

    &__item {
      color: get-color($text, 400);
      font-size: 1.2rem;
      line-height: 1.4rem;
      @include tableColRowCommon;
    }
  }

  &-body {
    margin-top: 2.8rem;

    &__row {
      display: flex;
      flex: 1;
      justify-content: space-between;
      height: 5rem;
      align-items: center;

      @include respond(tab-port) {
        position: relative;
      }
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $black;
      overflow: hidden;
      max-height: 4rem;
      @include tableColRowCommon;
    }

    &.has-scrolls {
      .table-body__item:last-child {
        padding-right: .5rem;
      }
    }
  }
}
