/**
 * Appcuese z-index: 999998
 */
.overlay-container {
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
  overflow: auto;
}
