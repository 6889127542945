@import '../../../styles/common';

.pending-mobile {
  width: 33rem;
  font-size: 1.2rem;

  .title {
    font-family: $body12;
    font-size: 1em;
  }

  .description {
    font-family: $body12;
    font-size: 1em;
    color: $divider-border-color;
  }

  .code-input__wrapper {
    display: flex;
    width: 100%;
    margin-top: 1rem;

    .two-factor-input {
      width: 1.6rem;
      height: 3.4rem;
      padding: .1rem .4rem;
      font-size: 1.4rem;

      &--container {
        width: 21rem;
      }

      &--separator {
        width: .7rem;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 11rem;
      margin: 2rem 0 0 1rem;

      button {
        padding: 0;
        font-family: $body12;
        font-size: 0.84em;
        line-height: 3;
        color: get-color($primary, 800);
        background-color: transparent;
        border: none;
        outline: none;
        text-transform: uppercase;
        cursor: pointer;

        &:disabled {
          opacity: .4;
          background-color: transparent;
        }
      }
    }
  }
}
