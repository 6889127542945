@import '../../../styles/common';

$mq-max-width-large: 43.75em;
$mq-max-width-medium: 34.38em;
$mq-max-width-small: 28.13em;

$mq-max-height-large: 54.38em;
$mq-max-height-semi_large: 46.88;
$mq-max-height-medium: 43.75em;
$mq-max-height-semi_medium: 40.63em;
$mq-max-height-small: 37.5em;
$mq-max-height-semi_small: 35em;

.agreement-form {
  position: fixed;
  z-index: 7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 58rem;
  padding: 2rem 3rem;
  background: $white;
  border: 1px solid get-color($bg, 200);
  box-sizing: border-box;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  &.small {
    height: 44rem;

    @media only screen and (max-width: $mq-max-width-small) {
      height: 50rem;
    }
  }

  @media only screen and (max-width: $mq-max-width-large) {
    width: 48rem;
  }

  @media only screen and (max-width: $mq-max-width-medium) {
    width: 38rem;
  }

  @media only screen and (max-width: $mq-max-width-small) {
    width: 29rem;
    padding: 2rem 1.6rem;
  }

  &__header {
    margin: 0 1.4rem;
    font-family: $title14;
    font-size: 3rem;
    line-height: 4rem;
    text-align: center;
    color: get-color($text, 900);


    @media only screen and (max-width: $mq-max-width-large) {
      font-size: 2.5rem;
      line-height: 3.2rem;
    }

    @media only screen and (max-width: $mq-max-width-medium) {
      font-size: 2rem;
      line-height: 2.4rem;
      margin: 0 3rem;
    }

    @media only screen and (max-width: $mq-max-width-small) {
      font-size: 2rem;
      line-height: 2.4rem;
      margin: 0;
    }
  }

  .divider {
    margin: 1.6rem 0;
  }

  &__link {
    text-decoration: none;
    font-size: 1.6rem;
    font-family: $body12;
    color: get-color($primary, 800);
    margin-top: 1rem;
  }

  &__body {
    height: 52.4rem;
    width: 100%;

    &.common-scroll {
      overflow-y: auto;
    }

    @media only screen and (max-height: $mq-max-height-large) {
      height: 42.6rem;
    }

    @media only screen and (max-height: $mq-max-height-semi_large) {
      height: 37.4rem;
    }

    @media only screen and (max-height: $mq-max-height-medium) {
      height: 32.4rem;
    }

    @media only screen and (max-height: $mq-max-height-semi_medium) {
      height: 28.4rem;
    }

    @media only screen and (max-height: $mq-max-height-small) {
      height: 25rem;
    }

    @media only screen and (max-height: $mq-max-height-semi_small) {
      height: 20rem;
    }

    .agreement-loader {
      position: absolute;
      margin-left: 38%;
      margin-top: 20rem;

      &.centered {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-top: 0;
      }

      @media only screen and (max-width: $mq-max-width-large) {
        margin-left: 35%;
      }

      @media only screen and (max-width: $mq-max-width-medium) {
        margin-left: 32%;
      }

      @media only screen and (max-width: $mq-max-width-small) {
        margin-left: 30%;
      }

      @media only screen and (max-height: $mq-max-height-large) {
        margin-top: 15rem;
      }

      @media only screen and (max-height: $mq-max-height-semi_large) {
        margin-top: 12.4rem;
      }

      @media only screen and (max-height: $mq-max-height-medium) {
        margin-top: 10rem;
      }

      @media only screen and (max-height: $mq-max-height-semi_medium) {
        margin-top: 7.6rem;
      }

      @media only screen and (max-height: $mq-max-height-small) {
        margin-top: 6.4rem;
      }

      @media only screen and (max-height: $mq-max-height-semi_small) {
        margin-top: 5.6rem;
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      &.disabled {
        opacity: .2;
      }

      .terms-and-conditions {
        display: flex;
        flex-direction: column;
        font-family: $body12;
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: get-color($text, 900);
        width: 95%;
        margin: 0 auto;

        & > :not(:first-child) {
          margin-top: 1.6rem;
        }

        .wallet-agreement-title {
          font-family: $title14;
          font-size: 1.8rem;
          line-height: 1.25;
          text-align: center;
          margin-right: 3.6rem;
          margin-left: 3.6rem;
        }

        .bold-list-item {
          font-family: $title14;
          font-size: 1.6rem;

          &_margin-left {
            margin-left: 1.6rem;
          }
        }

        .paragraph {
          position: relative;

          &.blurred:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                            to bottom,
                            rgba(255, 255, 255, 0) 0%,
                            rgb(255, 255, 255) 90%
            );
          }
        }
      }
    }
  }

  .agreement-checkbox-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: .5rem;
    padding-top: 2rem;
    width: 100%;
    background: $white;
    color: get-color($text, 800);

    .auth-checkbox {
      margin-top: 0;
    }

    &__link-wrapper {
      font-size: 1.6rem;
    }

    &__link {
      font-size: 1.6rem;
      line-height: 2.1rem;
      font-family: $body12;
      cursor: pointer;
      color: get-color($text, 800);
      text-decoration: none;
      border-bottom: 1px solid get-color($text, 800);
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 32.4rem;
    margin: .4rem 0;

    @media only screen and (max-width: $mq-max-width-large) {
      width: 30rem;
    }

    @media only screen and (max-width: $mq-max-width-medium) {
      width: 26.4rem;
    }

    @media only screen and (max-width: $mq-max-width-small) {
      width: 21.6rem;
    }

    button {
      font-family: $button16;
      font-size: 1.8rem;
      line-height: 2rem;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      &.secondary {
        color: get-color($text, 800);
      }

      &.primary {
        color: get-color($primary, 800);
      }

      &:disabled {
        opacity: .2;
      }

      &:focus {
        text-shadow: 1px 1px 2px get-color($bg, 400);
      }
    }
  }
}
