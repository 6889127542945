@import '../../../../styles/common';

.group-boxes__selection-dropdown {
  &__wrapper {
    position: relative;

    .dropdown {
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      overflow: scroll;
      width: 200px;
      height: 100px;
      margin-top: -19px;
      padding-top: 5px;
      border-radius: 4px;
      border: solid 1px #000584;
      outline: none;
      background-color: #ffffff;
      cursor: pointer;

      span {
        margin: 2px 0;
        font-family: $fontBold;
        font-size: 12px;
      }
    }
  }
}
