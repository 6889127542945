@import 'src/styles/common';

.payment-confirmation {
  position: relative;
  @include flexCenter;
  height: 100vh;
  margin: 0 4rem;

  &__header {
    position: absolute;
    top: 4rem;
    left: 4rem;

    @include respond(phone){
      left: 0;
    }

    .header__logo {
      @include headerLogo;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    max-width: 80rem;

    &__message {
      @include base-text($title14, 3rem, 4rem, $black);
    }

    &__button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}
