@import '../../../styles/common';

.progress {
  &-bar {
    display: flex;
    justify-content: space-between;
    background-color: get-color($bg, 200);
    border-radius: 100px;
    width: 100%;
    height: 5px;

    &--filled {
      background-color: get-color($bg, 900);
      border-radius: 100px 0 0 100px;

      &.rounded {
        border-radius: 100px;
      }
    }

    .reversed {
      display: flex;
      justify-content: flex-start;
      z-index: 2;
      border-radius: 0 100px 100px 0;

      .marker {
        min-width: 3px;
        width: 3px;
        height: 11px;
        margin-top: -3px;
        overflow: hidden;
        border-radius: 2px !important;
        -webkit-border-radius: 2px !important;
        -webkit-transform: translateZ(0);

        .marker-bg {
          width: 100%;
          height: 100%;
          background-color: get-color($bg, 900);
        }
      }

      .strokes-area {
        width: 98%;
        background-image: url('./../../../assets/images/slash.svg');
        border-radius: 0 100px 100px 0;
      }
    }
  }
}
