@import 'src/styles/common';

.progress-tooltip {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.default {
      opacity: 0;
    }

    &.left {

      .body {
        align-self: flex-start;
      }
    }

    &.right {

      .body {
        align-self: flex-end;
        margin-right: -.5rem;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: fit-content;
      width: -moz-fit-content;
      background-color: transparent;

      .amount {
        font-size: 1.4rem;
        font-family: $title18;
        line-height: 1.6rem;
        color: get-color($text, 900);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .description {
        @include base-text($body12, 1.2rem, 1.8rem, get-color($text, 900));
      }
    }

    .pointer {
      width: 0;
      height: 0;
      margin: .4rem 0 .9rem;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid get-color($bg, 900);
      position: relative;
    }
  }
}
