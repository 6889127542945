@import '../../../styles/common';

.mobile-input {
  @include AuthFormInput;

  display: flex;
  box-sizing: border-box;
  min-width: 20rem;
  padding-right: .5rem;

  &__wrapper {
    position: relative;
  }

  .selected-code-block {
    display: flex;
    align-items: center;
    min-width: 6.5rem;
    border: 1px solid transparent;
    border-right-color: get-color($bg, 500);
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    padding: 0 .6rem;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.2rem;
      margin-right: .6rem;
      font-family: $body12;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: get-color($text, 800);
    }

    img {
      width: .8rem;
      height: .4rem;
    }
  }

  input {
    font-size: 1.6rem;
    font-family: $title14;
    border: none;
    outline: none;
    width: 100%;
    padding: .8rem 0 .8rem .4rem;
  }
}

.mobile-dropdown {
  z-index: 5;
  position: absolute;
  overflow: auto;
  min-width: 20rem;
  width: 100%;
  height: 16.2rem;
  padding-top: 1.4rem;
  border-radius: 4px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid  get-color($bg, 200);
  background-color: $white;
  cursor: pointer;
  overflow-x: hidden;

  .country {
    display: flex;

    .code {
      width: 2.9rem;
      margin-left: 1.7rem;
      margin-right: 1.7rem;
      font-family: $title14;
      font-size: 1.2rem;
      line-height: 1.92;
      color: $black;

      &.selected {
        color: get-color($primary, 800);
      }
    }

    .name {
      margin-right: 1.7rem;
      font-family: $body12;
      font-size: 1.2rem;
      line-height: 1.92;
      color: $black;

      &.selected {
        color: get-color($primary, 800);
      }
    }
  }

  @media only screen and (max-height: 47.8em) {
    height: 15rem;
  }

  .divider {
    margin: .8rem 1.5rem;
    border-top: 2px dotted get-color($bg, 200);
  }
}
