@font-face {
    font-family: 'HeeboLight';
    src: url('../assets/fonts/Heebo-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'HeeboRegular';
    src: url('../assets/fonts/Heebo-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'HeeboBold';
    src: url('../assets/fonts/Heebo-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'WorkSansLight';
    src: url('../assets/fonts/WorkSans-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'WorkSansRegular';
    src: url('../assets/fonts/WorkSans-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'WorkSansSemiBold';
    src: url('../assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'WorkSansBold';
    src: url('../assets/fonts/WorkSans-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'PublicSansLight';
    src: url('../assets/fonts/PublicSans-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'PublicSansRegular';
    src: url('../assets/fonts/PublicSans-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'PublicSansSemiBold';
    src: url('../assets/fonts/PublicSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'PublicSansBold';
    src: url('../assets/fonts/PublicSans-Bold.ttf') format('truetype');
    font-weight: 700;
}
