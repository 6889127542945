@import 'src/styles/common';

.modal {
  display: flex;
  flex-direction: column;
  max-width: 37rem;
  background: $white;
  border: 1px solid get-color($bg, 200);
  border-radius: 29px;
  padding: 3.5rem;
  position: absolute;
  z-index: 999999;
  box-sizing: border-box;
  width: 100%;
  margin:5rem;
  max-height: calc(100vh - 3rem);

  &__close-icon {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    cursor: pointer;
  }

  &__body {
    overflow-y: auto;
    height: 35rem;
    transition: all 3s ease-in-out;
    display: flex;
    &.simplebar-scroll {
      overflow: hidden;
    }
  }

  @include respond(phone) {
    max-width: calc(100vw - 3rem) !important;
    padding: 2rem !important;
  }
}

.modal-wrapper {
  @include flexCenter;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
