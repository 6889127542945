@import 'src/styles/common';

.input-with-select {
  @include formInputContainerMixin;
  min-width: 30rem;
  box-sizing: border-box;

  &.not-valid {
    .input-with-select-content {
      border-color: get-color($error, 500);
    }
  }

  &-content {
    @include formInputContentMixin;
    padding: .6rem 1.4rem .6rem 1rem;
    border-radius: 5px;
    border: 1px solid transparent;

    input {
      @include formInput;
      font-family: $title18;
      line-height: 1.6rem;
      margin-right: .4rem;
    }

    &__close-icon {
      width: 1.6rem;
      height: 1.6rem;
      min-width: 1.6rem;
      min-height: 1.6rem;
      cursor: pointer;

      path {
        stroke: $black
      }
    }
  }
}
