@import './../../../styles/common';

.rounded-button {
  padding: 1rem 2.4rem;
  border-radius: 10rem;
  border: none;
  outline: none;
  background: get-color($bg, 900);
  color: $white;
  font-family: $title18;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;

  @mixin brightStyles {
    background: $white;
    color: get-color($text, 800);
    border: 1px solid get-color($bg, 300);
  }

  &__with-loader-content {
    display: flex;
    align-items: center;

    .loader-small {
      margin-right: .9rem;
      transform: scale(1.18);
    }
  }

  &.reload {
    @include brightStyles;
    height: 3.2rem;
    padding: .4rem 1.6rem;
    opacity: 1;
    transition: .4s opacity ease-in-out;

    &-loading {
      opacity: .5;
      transition: .4s opacity ease-in-out;
    }
  }

  &.white {
    @include brightStyles;
    padding: .5rem 1.5rem;
    border-radius: 5px;
  }

  &.bright {
    @include brightStyles;
    height: 4.2rem;
    opacity: 1;
    transition: .5s opacity ease-in-out;
  }

  &.bright-disabled {
    opacity: .5;
    transition: .5s opacity ease-in-out;
  }

  &.ghost {
    background: $white;
    color: get-color($text, 900);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }


  &.disabled {
    background: get-color($bg, 400);
    cursor: default;
  }

  &.secondary {
    background: none;
    color: get-color($text, 600);

    &.disabled {
      opacity: .4;
      cursor: default;
    }
  }
}
