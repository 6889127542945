@import 'src/styles/common';

.pending-card-row {
  &-info {
    width: 33rem;
    text-align: center;
    margin: 0 auto;

    &.inactive {
      opacity: .4;
    }

    span {
      display: block;
      font-family: $title18;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: get-color($text, 500);

      &:first-child {
        margin-bottom: .5rem;
        color: get-color($text, 900);
      }
    }
  }
}
