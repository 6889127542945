@import '../../../styles/common';

.switch {
  position: relative;
  width: 140px;
  height: 28px;
  border-radius: 18px;
  transition: background-color 100ms ease-out;
  z-index: 1;
  cursor: pointer;

  &:before {
    left: -6px;
  }

  &:after {
    right: -6px;
  }

  .toggle-button {
    position: absolute;
    width: 70px;
    height: 24px;
    background-color: #000584;
    border-radius: 18px;
    transition: transform 100ms ease-in-out;
    z-index: 3;
    top: 2px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-family: $fontRegular;
      font-size: 10px;
      line-height: 2.3;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .switch-on__label,
  .switch-off__label {
    display: none;
    height: 100%;
    font-family: $fontRegular;
    font-size: 10px;
    line-height: 2.3;
    color: #000584;
    text-transform: uppercase;
  }

  &_is-off {
    background-color: #ecf5fe;

    .switch-off__label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 18px;
    }
  }

  &_is-on {
    background-color: #ecf5fe;

    .switch-on__label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 18px;
    }
  }

  .toggle-button_position-left {
    transform: translateX(-1px);
  }

  .toggle-button_position-right {
    transform: translateX(70px);
  }

  &.disabled {
    .switch-on__label,
    .switch-off__label {
      color: #c3c3c3;
    }
    .toggle-button {
      background-color: #d9d9d9;
    }
    &.switch_is-on,
    &.switch_is-off {
      background-color: #f0f0f0;
    }
  }
}
