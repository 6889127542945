@import './../../../styles/common';

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  span {
    color: get-color($bg, 900);
    font-size: 14px;
    font-family: $body14;
    line-height: 35px;
    text-transform: uppercase;
  }

  &--big {
    width: 64px;
    height: 64px;
  }
}

.box__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  &:hover {
    border-radius: 50%;
  }
}
