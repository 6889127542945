@import 'src/styles/common';

.progress-info-message {
  position: fixed;
  z-index: 6;

  .body {
    position: absolute;
    float: left;
    transform: translate(-46%, -118%);
    width: 147px;
    height: 64px;
    padding: 10px;
    border-radius: 8px;
    font-family: $body12;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: get-color($text, 800);
    background: $white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}
