@import 'src/styles/common';

.pagination-item {
  @include flexCenter;
  @include base-text($publicSansSemiBold, 1.4rem, 2rem, get-color($text, 800));
  min-width: 3.2rem;
  min-height: 3.2rem;
  padding: .5rem .6rem;
  box-sizing: border-box;
  border-radius: .6rem;

  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;

  &:not(.disabled):not(.active):hover {
    background: get-color($bg, 200);
  }

  &.active {
    background: get-color($bg, 100);
    border: 1px solid get-color($bg, 600);
    color: get-color($bg, 900);
  }

  &.active,
  &.disabled {
    cursor: default;
  }
}
