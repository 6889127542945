@import 'src/styles/common';

.cell {
  &-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;

    &.with-error {
      color: get-color($error, 700);
      span {
        white-space: normal;
        color: get-color($error, 700)
      }
    }

    .order-errored{
      color: get-color($error, 700);
    }

    .errored-cell{
      display: flex;
      flex-direction: column;

      &__value{
        display: flex;
        justify-content: flex-start;
      }

      &__message {
        font-size: 1rem;
        line-height: 1.2rem;
        text-align: left;
      }
    }

    &.with-additional-info {
      display: flex !important;
      align-items: center !important;
      flex: 1;

      span:first-child {
        line-height: 2rem;
        @include lineClamp(1);
      }
    }

    .expanded-icon {
      margin: 0 .8rem;
      @include expandedIcon(90deg)
    }

    .additional-info {
      display: flex;
      justify-content: center;
      align-items: center;
      background: get-color($bg, 100);
      border: 1px solid get-color($bg, 300);
      border-radius: 5px;
      box-sizing: border-box;
      padding: 0 .5rem;
      height: 2rem;
      margin-left: auto;
      max-width: 3.2rem;

      &__label{
        font-size: 1rem;
        line-height: 2rem;
        color: get-color($text, 800);
        font-family: $title18;
        overflow: inherit;
      }
    }
  }
};
