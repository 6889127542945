@import '../../../styles/common';

@mixin textPosition {
  display: flex;
  align-items: center;
};

.spending-section {
  display: none;

  @include respond(phone) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .spending-item {
    margin-top: 1rem;
  }

  .spending-item:not(:last-child){
      margin-right: 1rem;
    }
}

.spending-item {
  background: get-color($bg, 100);
  border-radius: 5px;
  padding: .6rem 1.6rem;
  display: flex;

  &__key {
    color: get-color($text, 800);
    font-family:$title18;
    @include textPosition;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  &__value {
    margin-left: 1rem;
    font-family:  $title14;
    @include textPosition;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
