@import "src/styles/common";

.notification-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: get-color($secondary, 700);
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: $overline10;
  padding: .4rem .8rem;
  border-radius: 5px;
  border: 1px solid get-color($secondary, 500);
  background-color: transparent;
  cursor: pointer;
  margin: 0 .8rem;
  white-space: nowrap;
  text-decoration: none;
}
