@import '../../../styles/common';

.error-transactions {
  @include emptyTransactionBody;

  &__body {
    span {
      white-space: pre-line;
      text-align: center;
    }
  }
}
