@import 'src/styles/common';

.archived-card-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;

  &__background {
    height: 100%;
    opacity: 0.7;
    background-color: get-color($error, 500);

    &.suspended {
      background-color: get-color($bg, 500);
    }
  }

  &__status {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $overline10;
    font-size: 1rem;
    color: $white;
  }
}
