@import 'src/styles/common';

.load-more {
  display: flex;
  align-items: center;

  &__button {
    @include base-text($publicSansSemiBold, 1.4rem, 1.4rem, get-color($text, 900));
    padding: .9rem 2rem;
    height: 3.4rem !important;
    margin-right: .8rem;

    &.bright-disabled {
      cursor: default;
    }
  }

  &__info {
    @include base-text($publicSansNormal, 1.2rem, 1.6rem, get-color($text, 600));
  }
}
