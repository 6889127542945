@import "src/styles/common";

.right-modal {
  @keyframes rightModal {
    0% {transform: translateX(100%)}
    100% {transform: translateX(0)}
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 6rem 1rem 0 1rem;
  top: 0;
  right: 0;
  transform: translateX(100%);
  width: 59.2rem;
  height: 100vh;
  background-color: $white;
  z-index: 111111;
  margin-bottom: 5vh;
  box-sizing: border-box;
  animation: rightModal .7s ease-in-out 1;
  animation-fill-mode: forwards;

  &__close-icon{
    cursor: pointer;
    position: absolute;
    top: 1.8rem;
    right: 1.8rem;
    z-index: 22222;
  }

  .icon {
    margin-right: 1.2rem;
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
  }

  .divider  {
    width: 50%;
    margin: 5rem auto;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    word-break: break-all;

    .title {
      font-size: 3rem;
      line-height: 4rem;
      font-family: $title30;
      color:$black;
      margin-bottom: 1.5rem;
      max-width: 100%;
    }

    .sub-title {
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-family: $sub18;
      color: get-color($text, 700);
      margin-bottom: 1.5rem;
    }
  }

  .buttons-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;

    &__status-button {
      &.pending {
        background: get-color($warning, 200);
        color: get-color($warning, 800);
      }

      &.failed {
        background: get-color($error, 200);
        color: get-color($error, 800);
      }

      &.complete {
        background: get-color($secondary, 200);
        color: get-color($secondary, 800);
      }

      &.returned {
        background: get-color($bg, 200);
        color: get-color($text, 800);
      }
    }

    &__download-file{
      color: get-color($text, 800);
      background-color: $white;
      border: 1px solid get-color($bg, 400);
      padding: 1.1rem 2.6rem;
      margin-left: 1.5rem;

      &.loading{
        display: flex;
        color: get-color($text, 600);
        border-color: get-color($bg, 300);

        .download-button__loader-wrapper {
          margin-right: 1rem;
        }
      }
    }
  }

  &__delivering {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &.amount {
      margin-top: 4rem;
    }

    &.confirm {
      margin-top: 2.5rem;
    }

    .delivering-row {
      display: flex;
      width: 100%;
      margin-bottom: 2rem;

      &.no-margin-bottom {
        margin-bottom: 0;
      }

      &:nth-last-child(-n+3){
        margin-bottom: 5rem;
      }

      &:last-child {
        margin-bottom: 5rem;
      }

      &__label {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        font-family: $title18;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: get-color($text, 800);
        text-align: right;
        position: relative;

        span {
          display: flex;
          box-sizing: border-box;
          max-width: 25rem;
          padding-right: 1.2rem;
        }
      }

      &__value {
        position: relative;
        flex: 1;
        font-family: $title14;
        font-size: 1.8rem;
        line-height: 2.1rem;
        color: get-color($text, 900);
        display: flex;
        align-items: center;

        &.message {
          flex-direction: column;
          align-items: flex-start;
          max-width: 26.5rem;
          word-break: break-word;
          padding-left: 1.2rem;
        }

        &.date-value {
          span:not(:last-child){
            margin-bottom: .5rem;
          }
        }
      }

      &__expandable-value {
        @include expandedValuesMenu;
        padding-left: 1.2rem;

        .header-container{
          display: flex;
          align-items: center;

          span {
            word-wrap: break-word;
          }

          svg {
            margin: 0;
          }
        }
      }
    }
  }

  &__body {
    height: 65%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;

    @media only screen and (min-height: 50em) {
      height: 70%;
    }

    @media only screen and (min-height: 59em) {
      height: 75%;
    }

    @media only screen and (min-height: 68em) {
      height: 80%;
    }
  }

  .loader-wrapper{
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 6rem;
  }

  &__header,
  &__body,
  .buttons-section {
    padding-right: 4rem;
  }

  &__error {
    &-container {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &-message {
      font-family: $sub18;
      font-size: 2.6rem;
      line-height: 4.8rem;
      color: get-color($text, 600);
      margin-bottom: 6rem;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }
}
