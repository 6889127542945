.overflow-invisible {

  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: none;
  }

  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.simplebar-table {
  .simplebar-mask {
    right: .7rem;
  }

  .simplebar-track{
    &.simplebar-vertical{
      right: -.2rem;
    }
  }
}

.report-styles {
  .overflow-invisible{
    width: 101%;
  }

  .simplebar-track{
    &.simplebar-vertical{
      right: -.2rem;
    }
  }
  .simplebar-mask {
    right: .2rem;
  }
}
