$baseWidth: 2880;

$fontLight: 'HeeboLight', sans-serif;
$fontRegular: 'HeeboRegular', sans-serif;
$fontSemiBold: 'HeeboBold', sans-serif;
$fontBold: 'HeeboBold', sans-serif;

$title: 'WorkSansRegular', sans-serif;
$title14: 'WorkSansBold', sans-serif;
$title24: 'WorkSansBold', sans-serif;
$title30: 'WorkSansBold', sans-serif;
$overline10: 'PublicSansBold', sans-serif;
$body12: 'PublicSansRegular', sans-serif;
$body14: 'PublicSansRegular', sans-serif;
$caption10: 'PublicSansRegular', sans-serif;
$title18: 'PublicSansSemiBold', sans-serif;
$title40: 'PublicSansSemiBold', sans-serif;
$sub18: 'PublicSansSemiBold', sans-serif;
$sub12: 'PublicSansSemiBold', sans-serif;
$button16: 'PublicSansSemiBold', sans-serif;
$table: 'PublicSansSemiBold', sans-serif;
$table-amount: 'WorkSansBold', sans-serif;
$notificationTitle:'WorkSansSemiBold', sans-serif;

$publicSansNormal: "PublicSansRegular";
$publicSansSemiBold: "PublicSansSemiBold";
$publicSansBold: "PublicSansBold";

$workSansBold: "WorkSansBold";

$mainColor: #0078fc;
$selectedColor: #245dab;
$hoverColor: #1682fb;
$secondaryColor: #c6c6c6;

$suspendedColor: #ff5f5f;

/**
 * Divider
 */
$divider-border-color: #9e9e9e;
$divider-border-color-main: #000000;
$divider-default-horizontal-margin: 2rem;
$divider-default-vertical-margin: 2rem;
$divider-main-default-horizontal-margin: 2rem;

:export {
  fontLight: $fontLight;
  fontRegularHeebo: $fontRegular;
  publicSansSemiBold: $title40;
  fontRegular: $body12;
  fontBold: $title18;
  title14: $title14;
  suspendedColor: $suspendedColor;
}

$sidebar-width: 23.3rem;
