@import 'src/styles/common';

.file-uploader {
  &__file {
    box-sizing: border-box;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  &__header {
    display: grid;
    flex: 1;
    padding: 0 1.5rem;
    text-align: initial;
  }

  &__name,
  &__error {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__name {
    @include base-text($workSansBold, 1.4rem, 2rem, map-get($text, 900));
  }

  &__error {
    @include base-text($publicSansSemiBold, 1.2rem, 1.6rem, map-get($error, 700));
  }

  &__icon {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__actions {
    display: flex;
    flex-shrink: 0;

    & > *:not(:first-child) {
      margin-left: 1.5rem;
    }
  }

  &__repeat-icon {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;

    path {
      stroke: map-get($error, 700);
    }
  }

  &__close-icon {
    cursor: pointer;

    path {
      stroke: map-get($bg, 500);
    }

    &:hover {
      path {
        stroke: $black;
      }
    }
  }
}
