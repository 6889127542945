@import 'src/styles/common';

.dropdown-button {
  @include flexCenter;
  position: relative;
  padding: .5rem 1rem .5rem 1.5rem;
  border: 1px solid get-color($bg, 300);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  height: 3rem;
  white-space: nowrap;
  margin-right: 1.2rem;

  $transition: .2s all ease-in-out;

  &.disabled {
    cursor: default;
  }

  &.active {
    background-color: $white;
    transition: $transition;

    .dropdown-button__icon {
      transition: $transition;
      transform: rotate(-180deg);
    }
  }

  &__label {
    font-family: $title18;
    font-size: 1.4rem;
    line-height: 2rem;
    color: get-color($text, 900);
    margin-right: .5rem;
  }

  &__icon {
    transition: $transition;
    width: 1.6rem;
    height: 1.6rem;
    min-width: 1.6rem;
    min-height: 1.6rem;
  }
}
